import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;

export const invokeLabelDetection = async (fileUri, currentAccountId) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey, projectId } = getQueryParams(params, ['apiKey', 'projectId']);

  try {
    console.log('label detection process starting');
    const invokeNSFWResponse = await fetch(`https://8p9gaic31e.execute-api.us-east-1.amazonaws.com/default/CompositorClientLambda?operation=rekognition&rekognition_type=labels&apikey=${apiKey}&fileUri=${fileUri}&results_json=https://intrprtr.s3.amazonaws.com/accounts/${currentAccountId}/projects/${projectId}/processing/label_detection/results.json `);
    const responseJson = await invokeNSFWResponse.json();
    return invokeNSFWResponse;
  } catch (error) {
    console.error(error)
  }
};
