import { useEffect, useState, useRef, forwardRef } from 'react';
import './sample_video.scss';
import VideoLanguageButton from '../VideoLanguageButton/VideoLanguageButton';
import { isMobile } from "utils/isMobile.js";

const sources = [
  {
    src: 'https://examples-for-video-recognition.s3.amazonaws.com/videos/english_export.mp4',
    name: 'english'
  },
  {
    src: 'https://examples-for-video-recognition.s3.amazonaws.com/videos/spanish_export.mp4',
    name: 'spanish'
  },
  {
    src: 'https://examples-for-video-recognition.s3.amazonaws.com/videos/french_export.mp4',
    name: 'french'
  },
  {
    src: 'https://examples-for-video-recognition.s3.amazonaws.com/videos/chinese_export.mp4',
    name: 'Mandarin'
  },
];

const SampleVideoPreview = forwardRef(() => {
  const [currentVideo, setCurrentVideo] = useState(sources[0]);
  const [loaded, setLoaded] = useState(false);
  const [videoElWidth, setVideoElWidth] = useState(null);
  const videoRef = useRef();
  const previewRef = useRef();

  const handleChooseSampleVideo = (index) => {
    setLoaded(false);
    setCurrentVideo(sources[index]);
  };

  const renderLanguageButtons = () => {
    return sources.map((language, i) => {
      return (
        <VideoLanguageButton
          key={language.name}
          handleChooseSampleVideo={handleChooseSampleVideo}
          index={i}
          isSelected={currentVideo.name === language.name}
          label={language.name}
        />
      )
    })
  }

  const getNewWidth = () => {
    const widthOfButtons = 257;
    const marginAndPaddingForModal = isMobile() ? -120 : 120;
    return `${window.visualViewport.width - (widthOfButtons + marginAndPaddingForModal)}px`;
  }

  useEffect(() => {
    setVideoElWidth(getNewWidth());
    window.addEventListener('resize', () => {
      const newWidth = getNewWidth();
      setVideoElWidth(newWidth);
    });
  }, []);
  
  useEffect(() => {
    videoRef?.current.addEventListener('loadeddata', (e) => {
      setLoaded(true);
    });
  }, [videoRef.current]);

  return (
    <div className='sample-video-wrapper'>
      <div ref={previewRef} className='preview-container'>
        <div className='language-toggle-buttons'>
          {renderLanguageButtons()}
        </div>
        <div style={{width: videoElWidth, height: parseInt(videoElWidth) <= 1280 ? videoElWidth / 1.778 : 1280 / 1.778}} className='sample-video'>
          {!loaded && ('loading')}
          <video width={videoElWidth} autoPlay id='preview-video' ref={videoRef} data-loaded={loaded} controls src={currentVideo.src}></video>
        </div>
      </div>
    </div>
  )
})

export default SampleVideoPreview;
