import { createContext, useContext, useEffect, useRef } from "react";

const VideoRefContext = createContext();

export const VideoRefContextProvider = ({ children }) => {
  const videoElRef = useRef();

  return (
    <VideoRefContext.Provider
      value={{
        videoElRef
      }}
    >
      {children}
    </VideoRefContext.Provider>
  )
}

const useVideoRefContext = () => {
  return useContext(VideoRefContext);
}

export default useVideoRefContext;
