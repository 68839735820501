import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from 'auth/firebase';
import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk');

export const authorizeAccount = async ({ setCurrentAccountId, setIsAuthorizedForAccount, currentUser, setLoading }) => {
  const params = new URLSearchParams(window.location.search);
  const { accountId } = getQueryParams(params, ['accountId']);

  const userId = currentUser.uid;
  const accountRef = doc(db, 'account', accountId);

  try {
    const accountSnap = await getDoc(accountRef)

    if (accountSnap.exists()) {
      const { user_memberships } = accountSnap.data();
      const userIsAuthorized = !!user_memberships.find(id => id === userId);

      if (userIsAuthorized) {
        setCurrentAccountId(accountId);
      }
      setLoading(false)
      setIsAuthorizedForAccount(userIsAuthorized)
    }
  } catch (error) {
    console.error(error);
    console.error('no account Id found. contact administrator');
  }
}