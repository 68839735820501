import { reducerActions } from "constants/reducerActions";
import { uploadVideoFile } from "./uploadVideoFile";

const { SET_SRC, SET_SRC_NAME } = reducerActions;
const { REACT_APP_INTRPRTR_S3_PATH } = process.env;
// TODO make this a hook. It should return loading status and simplify where upload is needed. 

export const invokeUploadAndUpdateConfigs = async ({
  file,
  currentAccountId,
  setLoadingMessage = () => { },
  setLoading = () => { },
  projectId,
  dispatch,
  error = '',
  setError = () => { },
  setFileUri = () => { }
}) => {
  if (file && file.type === 'video/mp4') {
    setLoading(true)
    const videoUpLoadResponse = await uploadVideoFile(file, projectId, setLoadingMessage, currentAccountId);

    if (videoUpLoadResponse.ok) {
      const fileReference = `${REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/videos/${file.name}`
      setFileUri(fileReference)
      dispatch({ type: SET_SRC.actionName, payload: fileReference })
      dispatch({ type: SET_SRC_NAME.actionName, payload: file.name })
      setLoading(false)
      // Should update config when video has been uploaded
    } else {
      setLoading(false)
      setError('file upload failed. try again')
      // TODO: handle errors for multiple error types. 
      console.error(error)
    }
  }
}