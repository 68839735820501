export const modelTypes = [
  'codellama-7b.Q4_K_M.gguf',
  'llama-pro-8b.Q4_K_M.gguf',
  'tinyllama-1.1b-chat-v1.0.Q5_K_M.gguf',
  'mistral-7b-v0.1.Q4_K_M.gguf',
  'dolphin-2_6-phi-2.Q8_0.gguf',
  'phi-2.Q4_K_M.gguf',
  'llava-v1.6-mistral-7b.Q5_K_S.gguf',
  'openai-gpt-4-0125-preview',
  'openai-gpt-3.5-turbo-0125'
]