import { reducerActions } from "constants/reducerActions";

const {
  DELETE_TEXT_ENTRY,
  SET_ALL,
  SET_DESCRIPTION,
  SET_HLS_SRC,
  SET_PROBLEMATIC_CONTENT_RESULTS,
  SET_SRC_NAME,
  SET_SRC,
  SET_TITLE,
  SET_TRANSCRIPT_RESULTS,
  SET_LABEL_DETECTION_RESULTS,
  SET_VIDEO_TEXT_RESULTS,
  SET_MOI_PROMPT_REQUEST_RESULTS,
  SET_LABEL_DETECTION_OPTIMIZATION,
  SET_TRANSCRIPT_OPTIMIZATION,
  SET_VIDEO_TEXT_OPTIMIZATION,
  SET_PROBLEMATIC_CONTENT_OPTIMIZATION,
  SET_META_OPTIMIZATION_RESULTS,
  UPDATE_PROCESS_STARTED_STATE
} = reducerActions;

const configReducer = (state, action) => {

  switch (action.type) {
    case SET_ALL.actionName:
      return {
        ...state,
        ...action.payload
      };
    case SET_SRC.actionName:
      return {
        ...state,
        video: {
          ...state.video,
          mp4: action.payload
        }
      }
    case SET_SRC_NAME.actionName:
      return {
        ...state,
        video: {
          ...state.video,
          srcName: action.payload
        }
      }
    case SET_HLS_SRC.actionName:
      return {
        ...state,
        video: {
          ...state.video,
          hls: action.payload
        }
      }
    case DELETE_TEXT_ENTRY.actionName:
      return {
        ...state,
        interactives: state.interactives.map(innerArray => {
          return innerArray.filter(obj => obj.id !== action.payload.id)
        })
      }
    case SET_TITLE.actionName:
      return {
        ...state,
        project: {
          ...state.project,
          title: action.payload
        }
      }
    case SET_DESCRIPTION.actionName:
      return {
        ...state,
        project: {
          ...state.project,
          description: action.payload
        }
      }
    case SET_PROBLEMATIC_CONTENT_RESULTS.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          problematic_content: {
            ...state.video_metadata.problematic_content,
            // PAYLOAD should be an object containing the src and the raw results. 
            // there should be no need to update each independent of one another, as they should both hold the same state. 
            ...action.payload,
            processCompleted: true
          }
        }
      }
    case SET_VIDEO_TEXT_RESULTS.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          video_text: {
            ...state.video_metadata.video_text,
            ...action.payload,
            processCompleted: true
          }
        }
      }
    case SET_TRANSCRIPT_RESULTS.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          transcript: {
            ...state.video_metadata.transcript,
            ...action.payload,
            processCompleted: true
          }
        }
      }
    case SET_LABEL_DETECTION_RESULTS.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          label_detection: {
            ...state.video_metadata.label_detection,
            ...action.payload,
            processCompleted: true
          }
        }
      }
    case SET_MOI_PROMPT_REQUEST_RESULTS.actionName:
      return {
        ...state,
        moi_request_responses: [
          ...state.moi_request_responses,
          action.payload.rawResultsExample
        ]
      }
    case SET_LABEL_DETECTION_OPTIMIZATION.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          optimizations: {
            ...state.video_metadata.optimizations,
            label_detection: {
              ...state.video_metadata.optimizations.label_detection,
              ...action.payload
            }
          }
        }
      }
    case SET_PROBLEMATIC_CONTENT_OPTIMIZATION.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          optimizations: {
            ...state.video_metadata.optimizations,
            problematic_content: {
              ...state.video_metadata.optimizations.problematic_content,
              ...action.payload
            }
          }
        }
      }
    case SET_VIDEO_TEXT_OPTIMIZATION.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          optimizations: {
            ...state.video_metadata.optimizations,
            video_text: {
              ...state.video_metadata.optimizations.video_text,
              ...action.payload
            }
          }
        }
      }
    case SET_TRANSCRIPT_OPTIMIZATION.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          optimizations: {
            ...state.video_metadata.optimizations,
            transcript: {
              ...state.video_metadata.optimizations.transcript,
              ...action.payload
            }
          }
        }
      }
    case SET_META_OPTIMIZATION_RESULTS.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          optimizations: {
            ...state.video_metadata.optimizations,
            meta_optimization: {
              ...action.payload
            }
          }
        }
      }
    case UPDATE_PROCESS_STARTED_STATE.actionName:
      return {
        ...state,
        video_metadata: {
          ...state.video_metadata,
          [action.payload.process]: {
            ...state.video_metadata[action.payload.process],
            processStarted: true
          }
        }
      }
    default:
      return state;
  }
}

export default configReducer