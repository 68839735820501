import './sign_in_with_button.scss';
import { Button } from "@mui/material";

const SignInWithButton = ({
  Icon,
  onClick,
  type
}) => {
  const label = `sign in with ${type}`
  return (
    <Button
      className="sign-in-with-button"
      variant="contained"
      startIcon={<Icon />}
      onClick={onClick}
    >
      {label}
    </Button>
  )
}

export default SignInWithButton