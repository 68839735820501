import './editor.scss';
import useConfigContext from "providers/ConfigContextProvider/ConfigContextProvider";
import VideoElement from "./VideoElement";
import TranslationEditor from "./paths/TranslationEditor/TranslationEditor";
import { getQueryParams } from "utils/getQueryParams";
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { useEffect } from 'react';
import useDetermineProjectStatusAndUpdateUrl from 'utils/determineProjectStatusAndUpdateUrl';

const editorPathMapping = {
  translation: TranslationEditor
};

const Editor = ({ editorPath = 'translation' }) => {
  const params = new URLSearchParams(window.location.search)
  const { projectId } = getQueryParams(params, ['projectId']);
  const { projectConfig = {} } = useConfigContext();
  const { currentAccountId } = useAuthContext();
  const { REACT_APP_INTRPRTR_S3_PATH } = process.env;
  const source = `${REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/project.json`;

  // TODO: Probably okay to remove this Component mapping now and only render the 'translation editor' which needs to be changed itself.
  const Component = editorPathMapping[editorPath];
  const { checkAndUpdatePath } = useDetermineProjectStatusAndUpdateUrl();

  useEffect(() => {
    // Check Here for project config and if the url path contains the project Id. If a config exists and there is no query string for projectId it should be appended to the URL.
    checkAndUpdatePath({ projectConfig, currentAccountId })
  }, [])

  return (
    <div className="editor-root">
      <Component />
      <div className='video-preview-container'>
        <div className="player-outline">
          <VideoElement projectSource={source} />
        </div>
      </div>
    </div>
  );
}

export default Editor;
