import React from 'react';
import './account_page_sidebar.scss';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const AccountPageSideBarContainer = () => {
  const checkboxFields = [
    { label: 'NSFW content', value: 'nsfwContent' },
    { label: 'Text extraction', value: 'textExtraction' },
    { label: 'Label detection', value: 'labelDetection' },
    { label: 'Transcription', value: 'transcription' },
    { label: 'Is optimized', value: 'isOptimized' },
  ];

  const renderCheckboxes = () => {
    return checkboxFields.map((field, index) => (
      <FormControlLabel
        key={index}
        control={<Checkbox />}
        label={field.label}
      />
    ));
  };

  return (
    <div className="account-page-sidebar-root">
      <h2>Filters</h2>

      <div className="checkbox-container">
        <div className="disabled-overlay">
          <div className="coming-soon-label">Filters coming soon...</div>
        </div> {/* Overlay added here */}
        <h3>Services</h3>
        {renderCheckboxes()}
      </div>
    </div>
  );
};

export default AccountPageSideBarContainer;
