import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './Routes/PrivateRoute';
import AdminPrivateRoute from './Routes/AdminPrivateRoute';
import Home from '../components/Home';
import SignIn from '../components/user/SignInPage';
import Editor from '../components/Editor';
import Providers from '../providers';
import Account from '../components/Account';
import Error from 'components/Error';
import QuickStartWorkflow from 'components/QuickStartWorkflow';
import Admin from 'components/Admin';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Providers>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/signin' element={<SignIn />} />
          <Route path='/error' element={<Error />} />
          <Route
            path='/account'
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            path='/editor'
            element={
              <PrivateRoute>
                <Editor />
              </PrivateRoute>
            }
          />
          <Route path="/quick-start" element={
            // <PrivateRoute>
            <QuickStartWorkflow />
            // </PrivateRoute>
          } />
          <Route
            path='/admin'
            element={
              <AdminPrivateRoute>
                <Admin />
              </AdminPrivateRoute>
            }
          />
        </Routes>
      </Providers>
    </BrowserRouter>
  );
};

export default AppRouter;
