import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import { uploadVideoFile } from 'utils/aws/uploadVideoFile';
import { useEffect, useState } from 'react';
import { reducerActions } from 'constants/reducerActions';
import { getQueryParams } from 'utils/getQueryParams';
import { updateProjectState } from 'utils/aws/updateProjectState';
import { invokeTranscode } from 'utils/aws/invokeTranscode';
import usePingHlsSourceUrl from 'utils/aws/pingHlsSourceUrl';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';


const { SET_SRC, SET_SRC_NAME, SET_HLS_SRC } = reducerActions;

const { REACT_APP_INTRPRTR_S3_PATH } = process.env;

const UploadModal = ({ open, handleClose }) => {
  const { projectConfig = {} } = useConfigContext();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('loading');
  const [fileUri, setFileUri] = useState('')
  const { dispatch } = useConfigContext();
  const params = new URLSearchParams(window.location.search);
  const { hlsUrl } = usePingHlsSourceUrl();
  const { currentUser, currentAccountId } = useAuthContext();

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'video/mp4') {
      setLoading(true)
      const videoUpLoadResponse = await uploadVideoFile(file, projectConfig.id, setLoadingMessage, currentAccountId);

      if (videoUpLoadResponse.ok) {
        const fileReference = `${REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectConfig.id}/videos/${file.name}`
        setFileUri(fileReference)
        dispatch({ type: SET_SRC.actionName, payload: fileReference })
        dispatch({ type: SET_SRC_NAME.actionName, payload: file.name })
        // Should update config when video has been uploaded
      } else {
        setLoading(false)
        setError('file upload failed. try again')
        // TODO: handle errors for multiple error types. 
        console.error(error)
      }
    }

  };

  const updateConfigAndRunTranscode = async () => {
    handleClose();
    setLoading(false);
    setLoadingMessage('');
    // const invokeResponse = await invokeTranscode(fileUri, currentUser.uid); // TODO currentUser will need to change to currentAccountId
  }

  useEffect(() => {
    // TODO fix transcode to point at /accounts instead of /users
    if (!projectConfig?.video?.hls) {
      updateConfigAndRunTranscode();
    }
  }, [JSON.stringify(projectConfig?.video.mp4)])

  return (
    <Modal open={open} className="upload-modal">
      {!loading ? (<div className="upload-modal-content">
        <div>
          <h2>Upload Video</h2>
          <TextField
            type="file"
            accept="video/mp4"
            onChange={handleFileUpload}
            className="file-input"
          />
        </div>
        {error && (
          <div>
            {error}
          </div>
        )}
        <div className='upload-modal-cancel-button-wrapper'>
          <Button onClick={() => { handleClose(); }} className='upload-modal-cancel-button' variant='contained'>Cancel</Button>
        </div>
      </div>) : (
        <div>
          {loadingMessage}...
        </div>
      )}
    </Modal>
  )
}

export default UploadModal;
