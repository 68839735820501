export const languageSelectorButtonCss = {
  root: {
    flex: '4',
    backgroundColor: '#575555',
    color: '#C1BCBC',
    borderRadius: 0,
    color: '#C1BCBC',
    fontSize: '25px',
    boxShadow: 'none',
    '&:hover': {
      '&:not(.selected)': {
        backgroundColor: '#979797',
        color: '#010101'
      }
    },
    '&.selected': {
      backgroundColor: '#161515'
    },
  }
}