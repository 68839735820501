import React from 'react';
import { processingCompleteOptions } from 'constants/mockProjectData';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './project_list.scss'; // Assuming your styles are here
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { useNavigate } from 'react-router-dom';
import { getQueryParams } from 'utils/getQueryParams';
import CircularProgress from '@mui/material/CircularProgress';

const ProjectList = ({ projectObjects }) => {
  const { currentAccountId } = useAuthContext();
  const currentParams = new URLSearchParams(window.location.search);
  const { apiKey } = getQueryParams(currentParams, ['apiKey'])
  const navigate = useNavigate();

  const formatTime = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    let formattedMinutes = minutes.toString().padStart(2, '0');
    let formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  }
  const onRowClick = (id) => {
    const params = new URLSearchParams({
      accountId: currentAccountId,
      apiKey: apiKey,
      projectId: id
    })
    navigate(`/editor?${params}`);
  }

  const renderProgressSpinner = () => {
    return (
      <div className="progress-container">
        <div className="progress-inner-wrapper">
          <CircularProgress className="project-list-loading-spinner" />
        </div>
      </div>
    )
  }

  return (
    <TableContainer component={Paper} className="project-list-root">
      <Table className="project-list-table">
        <TableHead>
          <TableRow style={{ display: 'flex' }}>
            {/* <TableCell align="center" className="head-tile project-thumbnail-head">Thumbnail</TableCell> */}
            <TableCell align="center" className="head-tile project-title-head">Title</TableCell>
            <TableCell align="center" className="head-tile project-project-id-head">Project ID</TableCell>
            <TableCell align="center" className="head-tile project-project-duration-head">Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="project-list-body">
          {
            projectObjects.length ? projectObjects.map((project, index) => (
              <TableRow onClick={() => { onRowClick(project.id) }} className="table-row" key={index}>
                <div className="tablerow-inner-wrapper">
                  {/* <TableCell align="center" className="project-tile-thumbnail">
                  <img src={project.thumbnail} alt={project.title} style={{ width: '50px' }} />
                </TableCell> */}
                  <TableCell align="center" className="project-list-title-and-services">
                    <div className="project-list-title">
                      {project.title}
                    </div>
                    <div className="project-list-services">
                      {project.services.length ? project.services.map((process, index) => (
                        <React.Fragment key={index}>
                          {processingCompleteOptions[process]}
                          {index < project.services.length - 1 ? ' | ' : ''}
                        </React.Fragment>
                      )) : 'No processing'}
                    </div>
                  </TableCell>
                  <TableCell align="center" className="project-list-project-id">{project.id}</TableCell>
                  <TableCell align="center" className="project-list-project-duration">{formatTime(project.duration)}</TableCell>
                </div>
              </TableRow>
            )) : renderProgressSpinner()
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProjectList;
