// this function will invoke the global optimizer for however many processes have been run and the data has been optimized.

import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;

export const invokeGlobalOptimizer = async (fileUris, currentAccountId) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey, projectId } = getQueryParams(params, ['apiKey', 'projectId']);

  const queryParams = new URLSearchParams({
    apikey: apiKey,
    projectId,
    operation: 'optimize',
    optimizer_type: 'meta',
    fileUri: JSON.stringify(fileUris),
    fileUriIsArray: true,
    results_json: `https://intrprtr.s3.amazonaws.com/accounts/${currentAccountId}/projects/${projectId}/optimizers/meta_optimizer/results.json`,
  })
  try {
    console.log('label optimization process starting');
    const invokeNSFWResponse = await fetch(`https://8p9gaic31e.execute-api.us-east-1.amazonaws.com/default/CompositorClientLambda?${queryParams.toString()}`);
    const responseJson = await invokeNSFWResponse.json();
    return responseJson;
  } catch (error) {
    console.error(error)
  }
};

