import './sign_in_page.scss';
import React, { useEffect } from 'react';
import EmailSignIn from 'components/user/EmailSignIn';
import GoogleSignIn from 'components/user/GoogleSignIn';
import Container from '@mui/material/Container';
import { auth } from 'auth/firebase';

const SignIn = () => {

  // useEffect(() => {
  //   if (auth.currentUser && !userIsAuthorized) {
  //     setUserIsAuthorized(true);
  //   }
  // }, [JSON.stringify(auth.currentUser)]);

  return (
    <div className="sign-in-outer-root">
      <div className="sign-in-root" >
        <div className="sign-in-field-container">
          <Container component="main" maxWidth="xs">
            <EmailSignIn />
            <div className="sign-in-with-options">
              <GoogleSignIn />
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
