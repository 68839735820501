import './App.css';
import { IoMenuOutline } from 'react-icons/io5'
import AppRouter from 'Router/AppRouter';
import { Button } from '@mui/material';
import { getQueryParams } from 'utils/getQueryParams';
import { FaSignInAlt } from "react-icons/fa";


function App() {
  const onMenuButtonClick = () => {
    const confirmed = window.confirm('Start a new project?');

    if (confirmed) {
      const params = new URLSearchParams(window.location.search);
      const { projectId, apiKey = 'blah' } = getQueryParams(params, ['projectId']);
      if (projectId) {
        params.delete('projectId');
      }
      const newSearch = params.toString();
      window.location.href = `/editor?${newSearch}`;
    }
  };

  const onSignInClick = () => {
    window.location.pathname = '/signin'
  }

  const shouldRenderProjectButton = (pathname) => {
    const omitList = [
      '/',
      '/signin'
    ];

    if (omitList.includes(pathname)) {
      return false;
    }

    return true;
  }
  return (
    <div className="App">
      <div className='top-nav-bar'>
        {(
          <div className="nav-menu-icon">
            <Button
              className="new-project-button"
              onClick={onMenuButtonClick}
              variant="contained"
              style={{
                display: shouldRenderProjectButton(window.location.pathname) ? 'flex' : 'none',
                marginTop: '5px',
                minWidth: '190px'
              }}
            >
              Create New Project
            </Button>
            {/* <IoMenuOutline onClick={onMenuButtonClick} /> */}
          </div>
        )}
        {window.location.pathname === '/' && (
          <div className="nav-menu-icon">
            <Button
              className="signin-button"
              onClick={onSignInClick}
              variant="contained"
              style={{
                display: 'flex',
                margin: '0 5px 0 5px',
              }}
            >
              Sign In
            </Button>
            {/* <IoMenuOutline onClick={onMenuButtonClick} /> */}
          </div>
        )}
        {/* <div className='placeholder-logo'>
          intrprtr.io
        </div> */}
      </div>
      <AppRouter />
    </div>
  );
}

export default App;
