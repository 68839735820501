import { useEffect, useRef, useState } from 'react';
import useLanguage from 'providers/LanguageContextProvider/LanguageContextProvider';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import { isValidUrl } from 'utils/isValidUrl';
import useVideoContext from 'providers/VideoRefProvider/VideoRefProvider';

const VideoElement = ({ projectSource }) => {
  const { REACT_APP_FLEX_PLAYER_SCRIPT_SOURCE, REACT_APP_FLEX_PLAYER_STYLESHEET } = process.env;
  const { getCurrentLanguage } = useLanguage();
  const { projectConfig } = useConfigContext();
  const [canLoadSrcUrl, setCanLoadSourceUrl] = useState(false);
  const { videoElRef } = useVideoContext();

  useEffect(() => {
    if (canLoadSrcUrl) {
      const script = document.createElement('script');
      script.id = 'flex-player-embed'
      script.src = REACT_APP_FLEX_PLAYER_SCRIPT_SOURCE;
      script.async = true;
      script.type = 'module'
      document.body.appendChild(script);

      const playerStylesheet = document.createElement('link');
      playerStylesheet.href = REACT_APP_FLEX_PLAYER_STYLESHEET;
      playerStylesheet.rel = 'stylesheet'
      document.body.appendChild(playerStylesheet);
    }
    return () => {
      setCanLoadSourceUrl(false)
    }
  }, [canLoadSrcUrl]);

  useEffect(() => {
    if (videoElRef.current?.eventRelay) {
      videoElRef?.current?.eventRelay?.emit('lang_change', { lang: getCurrentLanguage() })
    }
  }, [getCurrentLanguage()]);

  const checkForValidUrl = async () => {
    const sourceFileUrlIsValid = await isValidUrl(projectSource)
    setCanLoadSourceUrl(sourceFileUrlIsValid);
  };

  useEffect(() => {
    checkForValidUrl();
  }, []);

  const renderEmbed = () => {
    return (
      <flexible-embed
        src={!canLoadSrcUrl ? null : projectSource}
        srcOverrides={canLoadSrcUrl ? null : JSON.stringify(projectConfig)}
        lang={getCurrentLanguage()}
        ref={videoElRef}
        className="video-container"
      ></flexible-embed>
    )
  };

  return (
    <>
      {renderEmbed()}
    </>
  )
}

export default VideoElement;


const formatPlayerJson = () => { }