import { reducerActions } from "constants/reducerActions"
import useS3Ping from "utils/aws/useS3Ping";

const {
  SET_PROBLEMATIC_CONTENT_RESULTS,
  SET_TRANSCRIPT_RESULTS,
  SET_LABEL_DETECTION_RESULTS,
  SET_VIDEO_TEXT_RESULTS,
} = reducerActions

const actions = [
  SET_PROBLEMATIC_CONTENT_RESULTS,
  SET_TRANSCRIPT_RESULTS,
  SET_LABEL_DETECTION_RESULTS,
  SET_VIDEO_TEXT_RESULTS
];


const useCheckForUnfinishedProcessData = ({ dispatch }) => {
  const { startPinging } = useS3Ping({ dispatch });
  const checkForDataFromUnfinishedProcesses = (unfinishedProcesses) => {
    unfinishedProcesses.forEach(process => {
      const processAction = actions.find(action => action.propName === process);
      startPinging({ action: processAction })
    });
  }

  return {
    checkForDataFromUnfinishedProcesses
  }
}

export default useCheckForUnfinishedProcessData;
