import VideoDetailsInputFields from "../VideoDetailsInputFields/VideoDetailsInputFields";
import VideoDetailsUpload from "../VideoDetailsUpload";

const VideoDetails = () => {
  return (
    <div className='video-details-container'>
      {/* <VideoDetailsUpload /> */}
      <VideoDetailsInputFields />
    </div>
  )
}

export default VideoDetails;
