import React, { useCallback, useEffect, useState } from 'react';
import './upload_video.scss';
import { invokeUploadAndUpdateConfigs } from 'utils/aws/invokeUploadAndUpdateConfigs';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import { PiSealCheckDuotone } from "react-icons/pi";
import QuickStartButtons from '../QuickStartButtons';
import { getAreProcessesFinished, noProcessStarted, numberOfProcessStarted } from 'utils/areProcessesFinished';

const UploadVideo = ({ setActiveStep }) => {
  const [dragging, setDragging] = useState(false);
  const [videoData, setVideoData] = useState(null);
  const [videoStaged, setVideoStaged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const { currentAccountId } = useAuthContext();
  const { projectConfig = {}, dispatch } = useConfigContext() || {};
  const [videoUploaded, setVideoUploaded] = useState(false);
  const { video_metadata = {} } = projectConfig || {};
  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    handleSetFile(e)
  }, [setVideoStaged]);

  const handleSetFile = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setVideoData({
        name: file.name,
        type: file.type,
        size: file.size,
        file: file
      })

      setVideoStaged(true); // Update state in parent to enable the 'Continue' button
    }
  }

  const uploadVideo = () => {
    invokeUploadAndUpdateConfigs({
      file: videoData.file,
      dispatch,
      currentAccountId,
      projectId: projectConfig.id,
      setLoading,
      setLoadingMessage
    })
  }

  useEffect(() => {
    if (projectConfig?.video?.mp4) {
      setVideoUploaded(true)
      setVideoData({
        name: projectConfig.video.srcName
      })
    }
  }, [projectConfig?.video?.mp4])

  const renderLoadingMessage = () => {
    if (videoUploaded) return null;

    if (loading) {
      return loadingMessage;
    }

    return 'Drag & Drop your video here or click the button below to upload';
  };

  useEffect(() => {
    const processStartedAndFinished = numberOfProcessStarted(video_metadata) === 4 && getAreProcessesFinished(video_metadata);
    const processNotStarted = !numberOfProcessStarted(video_metadata)
    if (processStartedAndFinished) {
      setActiveStep(3)
    }

    if (videoUploaded && processNotStarted) {
      setLoading(false);
      setActiveStep(2);
    }
  }, [videoUploaded, JSON.stringify(video_metadata)]);


  return (
    <div className={`upload-video ${dragging ? 'dragging' : ''}`}>
      <div className="upload-wrapper">
        <div
          className="button-and-drag-area"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className='video-info-area'>
            {videoData && (
              <p>
                <h2>
                  <span className="video-title"> {videoData?.name}</span>
                </h2>
              </p>
            )}
          </div>
          {videoUploaded && (
            <>
              <div className="complete-icon">
                <PiSealCheckDuotone />
              </div>
              <div className='complete-label'>
                Upload complete, Ready to Process
              </div>
            </>
          )}
          <div className="button-and-prompt-text">
            <div className="drag-area-wrapper">
              <div className="drag-drop-area"></div>
            </div>
            <div className="button-wrapper">
              <QuickStartButtons
                videoUploaded={videoUploaded}
                videoStaged={videoStaged}
                uploadVideo={uploadVideo}
                handleSetFile={handleSetFile}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;
