import { reducerActions } from "constants/reducerActions"
import { optimizeNSFWContent } from "./optimizeNSFWContent";
import { optimizeLabelDetection } from "./optimizeLabelDetection";
import { optimizeVideoTextDetection } from "./optimizeVideoTextDetection";
import { optimizeTranscription } from "./optimizeTranscription";

const {
  SET_PROBLEMATIC_CONTENT_OPTIMIZATION,
  SET_LABEL_DETECTION_OPTIMIZATION,
  SET_VIDEO_TEXT_OPTIMIZATION,
  SET_TRANSCRIPT_OPTIMIZATION
} = reducerActions;

const typeHandlerMap = {
  'problematic_content': {
    handler: optimizeNSFWContent,
    action: SET_PROBLEMATIC_CONTENT_OPTIMIZATION
  },
  'label_detection': {
    handler: optimizeLabelDetection,
    action: SET_LABEL_DETECTION_OPTIMIZATION
  },
  'video_text': {
    handler: optimizeVideoTextDetection,
    action: SET_VIDEO_TEXT_OPTIMIZATION
  },
  'transcript': {
    handler: optimizeTranscription,
    action: SET_TRANSCRIPT_OPTIMIZATION
  }
};

export const invokeOptimizersAndStartPinging = ({
  startPinging,
  completedProcesses,
  currentAccountId
}) => {
  completedProcesses.forEach(process => {
    const handler = typeHandlerMap[process].handler;
    handler(currentAccountId);
    startPinging({ action: typeHandlerMap[process].action })
  });
};