import React, { useState } from 'react';
import { Button, Typography, Box, Tabs, Tab } from '@mui/material';
// import './metadata_tab.scss';
import MOIPromptAndResponse from '../MOIPromptAndResponse';


const MetadataTab = ({ mp4File }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return <MOIPromptAndResponse />;
};

export default MetadataTab;
