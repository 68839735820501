import useVideoRefContext from "providers/VideoRefProvider/VideoRefProvider"
import TranslationTableRow from "../TranslationTableRow/TranslationTableRow"

const TranslationTableRows = ({ 
  interactiveArrays = [], 
  pageIndex, 
  dispatch,
  lang,
}) => {
  return interactiveArrays[pageIndex]?.map(i => {
      return (
        <TranslationTableRow 
          {...i} 
          lang={lang} 
          dispatch={dispatch} 
        />
      )
    })
}

export default TranslationTableRows;
