// returns 

export const getQueryParams = (params, list) => {
  const values = {};

  for (let i=0; i < list.length; i++) {
    const item = list[i];
    values[item] = params.get(item)
  }

  return values;
}
