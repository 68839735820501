import { db } from "auth/firebase";

import { getDocs, query, where, collection } from "firebase/firestore";
import { addUserRecord } from "./addUserRecord";

export const checkForUserAndUpdateDb = async ({ user }) => {
  const userRef = collection(db, 'user');
  // This functions purpose is to check for new users and add a user record for them if they do not exist.
  const firestoreUserQuery = query(userRef, where('email', '==', user.email));

  try {
    const querySnapshot = await getDocs(firestoreUserQuery);
    // if the query snapshot is empty, we can assume there are no matching users. 
    const recordExists = !querySnapshot.empty;
    if (!recordExists) {
      const userAdded = await addUserRecord(user);
    }
  } catch (error) {
    console.error(error)
  }
}