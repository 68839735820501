export const serviceTypesAndPrompts = {
  'freeform': {
    prompt: null,
    label: 'Ask a question'
  },
  'seo': {
    prompt: "you are an seo expert. Based on the context, provide the following metadata fields with value optimal for SEO. provide the response in json. Each field should have its own property. The fields are 'tags','keywords', 'description', 'content rating' and 'language'. format all of the values as strings",
    label: 'SEO Metadata'
  },
  'problematic_content': {
    prompt: 'Provide an brand safety audit for this video. Be sure to consider context when interpreting the problematic content results, including setting, audience and subject matter when determining whether or not there are concerns regarding the potential risk to the brand.',
    label: 'Brand Safety'
  }
}