import { db } from "auth/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

export const getUserIsAdmin = async (userId) => {
  const usersCollectionRef = collection(db, "user");
  const q = query(usersCollectionRef, where("id", "==", userId));

  try {
    const querySnapshot = await getDocs(q);
    let user;
    if (!querySnapshot.empty) {
      user = querySnapshot.docs[0].data();
    }

    return user && user.role === 'admin' ? true : false;
  } catch (error) {
    console.error("Error fetching user: ", error);
    return null;
  }
}
