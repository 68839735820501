import './left_panel_tabs.scss'
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import { FiSave, FiPlusSquare } from 'react-icons/fi'
import { updateProjectState } from 'utils/aws/updateProjectState';
import { formatProjectConfig } from 'utils/formatProjectConfig';

const LeftPanelTabs = ({ setActiveTab, activeTab }) => {
  const { projectConfig } = useConfigContext();
  const { currentUser, currentAccountId } = useAuthContext();
  const onClickTab = (e) => {
    setActiveTab(e.target.id)
  }

  const getActiveTabClass = (type) => {
    if (type == activeTab) {
      return 'active-tab';
    };
  };

  const onSave = async (config) => {
    const formattedProjectConfig = formatProjectConfig(projectConfig);
    updateProjectState(formattedProjectConfig, currentAccountId)
  }

  return (
    <div className="left-panel-tab-container">
      <ButtonGroup variant='contained'>
        <Button className={`${getActiveTabClass('video')} panel-tab`} onClick={onClickTab} id='video'>Video</Button>
        {/* <Button className={`${getActiveTabClass('translate')} panel-tab`} onClick={onClickTab} id='translate'>Translate</Button> */}
        <Button className={`${getActiveTabClass('metadata')} panel-tab`} onClick={onClickTab} id='metadata'>metadata</Button>
        <Button className={`${getActiveTabClass('ai-tab')} panel-tab`} onClick={onClickTab} id='model-picker'>models</Button>
      </ButtonGroup>
      {/* <div className="left-panel-buttons-wrapper"> */}
      {/* <div className="new-Project-button-container">
          <Button
            onClick={onSave}
            variant="contained"
            className="save-button"
            endIcon={<FiPlusSquare className="save-icon" />}
          >
            {/* <span className="save-button-label">New Project</span> */}
      {/* </Button> */}
      {/* </div>  */}
      < div className="save-button-container" >
        <Button
          onClick={onSave}
          variant="contained"
          className="save-button"
          endIcon={<FiSave className="save-icon" />}
        >
          <span className="save-button-label">save</span>
        </Button>
      </div >
      {/* </div > */}
    </div >
  )
}

export default LeftPanelTabs;
