import useConfigContext from "providers/ConfigContextProvider/ConfigContextProvider";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParams } from "utils/getQueryParams";
import { invokeTranscode } from "./invokeTranscode";
import { reducerActions } from "constants/reducerActions";
import useAuthContext from "providers/AuthContextProvider/AuthContextProvider";

const { SET_HLS_SRC } = reducerActions;

const usePingHlsSourceUrl = (url, interval = 30000, delay = 30000, maxAttempts = 10) => {
  const { dispatch, projectConfig } = useConfigContext();
  const [hlsUrl, setHlsUrl] = useState('')
  const [componentLoaded, setComponentLoaded] = useState(false);
  const [processingMessage, setProcessingMessage] = useState('')
  const params = new URLSearchParams(useLocation().search);
  const { projectId } = getQueryParams(params, ['projectId',]);
  const { currentUser, currentAccountId } = useAuthContext();

  let attempts = 0;

  const ping = () => {
    attempts++;
    const hlsFileName = `${projectConfig.video.srcName.split('.mp4')[0]}.m3u8`;
    const eventualSrc = `${process.env.REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/videos/hls/${hlsFileName}`;

    fetch(eventualSrc)
      .then(response => {
        if (response.status === 200) {
          console.log("Received 200 OK from S3 URL:", eventualSrc);
          setHlsUrl(eventualSrc);
          setProcessingMessage('');
        } else {
          throw new Error(`Received ${response.status} from S3 URL: ${url}`);
        }
      })
      .catch(error => {
        console.error("Error pinging S3 URL:", error);
        if (attempts < maxAttempts) {
          console.log(`Retrying in ${interval / 1000} seconds... (Attempt ${attempts}/${maxAttempts})`);
          setTimeout(ping, interval);
        } else {
          console.error(`Failed to retrieve 200 OK from ${url} after ${maxAttempts} attempts.`);
          setProcessingMessage('HLS rendition not found. Click to the right to restart')
        }
      });
  };

  const getProcessingMessage = () => {
    return processingMessage
  }

  useEffect(() => {
    if (hlsUrl) {
      dispatch({ type: SET_HLS_SRC.actionName, payload: hlsUrl })
    }
  }, [hlsUrl])

  useEffect(() => {
    if (projectConfig?.video.mp4 && !projectConfig?.video.hls) {
      // TODO UN COMMENT TO PING FOR HLS SOURCE
      // startPinging();
      // hacky, temporary solution to checking the file status. uses the existence of the m3u8 src url as the basis 
      // for whether or not the process is running. 
      setProcessingMessage('Transcoding in process...')
    }
  }, [JSON.stringify(projectConfig?.video?.mp4)])

  const startPinging = () => {
    setTimeout(ping, delay);
  }

  // Wait for 'delay' ms before starting the pinging
  return {
    hlsUrl: hlsUrl,
    processingMessage: processingMessage,
    getProcessingMessage
  }
}

// Usage example:
// pingS3UrlUntil200("https://your-s3-bucket-url.com/yourfile.ext");
export default usePingHlsSourceUrl;
