import './services.scss';

const services = [
  {
    "name": "Video Upload",
    "active": true
  },
  {
    "name": "Transcoding",
    "active": true
  },
  {
    "name": "Metadata Extraction and Analysis",
    "active": true
  },
  {
    "name": "Distribution",
    "active": true
  },
  {
    "name": "Integrated Serverless LLM Engine",
    "active": true
  }
];

const useCases = [
  {
    name: 'Brand Safety Audits',
    active: true
  },
  {
    name: 'Automated SEO data generation',
    active: true
  },
  {
    name: 'Precise content Matching',
    active: true
  }
]

const Services = () => {
  return (
    <div className='copy-services-list-wrapper'>
      <div className="inner-wrapper">

        <h2 className="copy-services-header">
          Features
        </h2>
        <ul className="copy-services-list">
          {services.map(service => {
            return (
              <li className="copy-services-list-item">
                <span>{service.name}</span>
                <span className='coming-soon-label'>{!service.active && (' (coming soon)')}</span>
              </li>
            )
          })}
        </ul>
        <h2 className="copy-services-header">
          Use cases
        </h2>
        <ul className="copy-services-list">
          {useCases.map(service => {
            return (
              <li className="copy-services-list-item">
                <span>{service.name}</span>
                <span className='coming-soon-label'>{!service.active && (' (coming soon)')}</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default Services;
