import './account_page.scss'
import useAuthContext from "providers/AuthContextProvider/AuthContextProvider";
import { useEffect, useState } from "react";
import ProjectListContainer from "./ProjectListContainer";
import AccountPageSideBarContainer from "./AccountPageSideBar";
import SearchBarWithOverlay from './SearchBar/SearchBar';


const AWS = require('aws-sdk');

const Account = () => {
  const { currentAccountId, currentUser } = useAuthContext();
  const [projectIdsList, setProjectIdsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectObjects, setProjectObjects] = useState([]);

  const getCurrentProjectIdsList = (projectPrefixArray) => {
    const list = projectPrefixArray.map((item) => {
      const projectId = item.Prefix.match(/projects\/([a-f0-9-]+)/i)[1]
      return projectId
    })

    const startIndex = (currentPage - 1) * 10;

    return list.slice(startIndex, startIndex + 10);
  }

  const getAccountProjects = async () => {
    const auth = await fetch(`https://b0dyd33dl4.execute-api.us-east-1.amazonaws.com/default/get_signed_token?apikey=blah`);
    const authData = await auth.json();

    AWS.config.credentials = {
      accessKeyId: authData.AccessKeyId,
      secretAccessKey: authData.SecretAccessKey,
      sessionToken: authData.SessionToken,
    };

    const s3 = new AWS.S3();
    const s3ListParams = {
      Bucket: 'intrprtr',
      Prefix: `accounts/${currentAccountId}/projects/`,
      Delimiter: 'project.json'
    };

    s3.listObjectsV2(s3ListParams, (e, data) => {
      setProjectIdsList(getCurrentProjectIdsList(data.CommonPrefixes));
    })
  };

  const extractServicesFromProjectData = (video_metadata) => {
    const services = Object.keys(video_metadata).filter(data_type => {
      const data = video_metadata[data_type];
      return data_type !== 'optimizations' && data.src;
    })
    return services;
  }

  const fetchCurrentListProjectData = async (id) => {
    const response = await fetch(process.env.REACT_APP_INTRPRTR_S3_PATH + `/accounts/${currentAccountId}/projects/${id}/project.json`);
    const responseJson = await response.json();
    const { video, project, video_metadata } = responseJson;

    setProjectObjects((state) => {
      const currentProjectIdAlreadySaved = state.find(p => p.id === responseJson.id);

      if (!currentProjectIdAlreadySaved) {
        return [
          ...state,
          {
            title: project.title || 'no title',
            description: project.description || 'no description',
            mp4: video.mp4,
            id: responseJson.id,
            services: extractServicesFromProjectData(video_metadata),
            duration: 120
          }
        ]
      }
    })

  }
  useEffect(() => {
    if (projectIdsList.length) {
      projectIdsList.forEach(id => {
        fetchCurrentListProjectData(id)
      })
    }
  }, [projectIdsList]);

  useEffect(() => {
    if (currentAccountId && currentUser) {
      getAccountProjects();
    }
  }, [currentAccountId])

  return (
    <div className="account-page-root">
      <AccountPageSideBarContainer />
      <div className='account-project-list-root'>
        <ProjectListContainer projectObjects={projectObjects} />
      </div>
    </div>
  )
}

export default Account;