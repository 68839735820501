import { Table, TableCell, TableHead, TableRow } from "@mui/material"
import TranslationSettingsButton from "../TranslationSettingsButton/TranslationSettingsButton";
import TranslationTableLanguageDropdown from "../TranslationTableLanguageDropdown/TranslationTableLanguageDropdown";

const TranslationTableHeader = ({ setSelectedLanguage, selectedLanguage }) => {
  return (
    <Table>
        <TableHead>
          <TableRow className='translation-table-header-row'>
            <TableCell className="translation-table-header-cell base-language-header-cell">Base Language: en</TableCell>
            <TableCell className="translation-table-header-cell translation-column">
                {/* THis is where the dropdown will go */}
              <TranslationTableLanguageDropdown selectedLanguage={selectedLanguage} setSelectedLanguage={setSelectedLanguage} />
            </TableCell>
            <TableCell>
              <TranslationSettingsButton />
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
  )
}

export default TranslationTableHeader;
