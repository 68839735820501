import React, { useReducer, useContext, createContext, useEffect, useState, useRef } from 'react';
import configReducer from '../../reducers/configReducer/configReducer';
import { getQueryParams } from 'utils/getQueryParams';
import { splitOverlaysIntoSmallerArrays } from 'utils/splitOverlaysIntoSmallArrays';
import { defaultState } from './defaultState';
import { useNavigate, useLocation } from 'react-router-dom';
import useVideoRefContext from 'providers/VideoRefProvider/VideoRefProvider';
import { updateProjectState } from 'utils/aws/updateProjectState';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import useCheckForUnfinishedProcessData from 'utils/config/checkForDataFromUnfinishedProcesses';

const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const params = new URLSearchParams(useLocation().search);
  const { projectId } = getQueryParams(params, ['projectId']);
  const [projectConfig, dispatch] = useReducer(configReducer, projectId ? null : defaultState);
  const [loaded, setLoaded] = useState(false);
  const [checkedForUnfinishedProcesses, setCheckedForUnfinishedProcesses] = useState(false);
  const { videoElRef } = useVideoRefContext();
  const { currentAccountId, isAuthorizedForAccount } = useAuthContext();
  const { checkForDataFromUnfinishedProcesses } = useCheckForUnfinishedProcessData({ dispatch });

  const getCurrentSource = () => {
    // For now, the current source will always be the .m3u8 file if it exists. 
    // otherwise default to the mp4
    const { video = {} } = projectConfig;
    const { hls = '', mp4 = '' } = video;

    return hls || mp4;
  };

  const getUpdateData = () => {
    const uri = getCurrentSource();
    const fileType = uri.endsWith('m3u8') ? 'hls' : 'mp4'

    return {
      uri: getCurrentSource(),
      type: fileType
    }
  };

  useEffect(() => {
    // check the config, only on the first load, for unfinished process requests. 

    if (loaded && !checkedForUnfinishedProcesses) {
      const { video_metadata } = projectConfig;

      const unfinishedProcesses = Object.keys(video_metadata).filter(type => {

        if (type === 'optimizations') {
          return false;
        }

        const metadataObj = video_metadata[type]
        const { processStarted, processCompleted } = metadataObj
        const srcExists = !!metadataObj.src
        const dataExampleExists = !!metadataObj.rawResultsExample || Object.keys(metadataObj.rawResultsExample).length;

        return processStarted && !processCompleted && (!srcExists || !dataExampleExists)
      })
      checkForDataFromUnfinishedProcesses(unfinishedProcesses);
      setCheckedForUnfinishedProcesses(true)
    }

  }, [JSON.stringify(projectConfig)])
  // // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Define the properties to omit
  const OMITTED_PROPERTIES = ['project'];
  // Store previous state for comparison
  const [previousConfig, setPreviousConfig] = useState(projectConfig);

  // Function to check for changes, excluding specified properties
  const checkForChanges = (currentConfig, previousConfig = {}) => {
    for (const key in currentConfig) {

      if (OMITTED_PROPERTIES.includes(key)) continue;
      if (JSON.stringify(currentConfig[key]) !== JSON.stringify(previousConfig[key])) {
        console.log('State updated') // TODO make this only on DEV
        updateProjectState(currentConfig, currentAccountId);
        setPreviousConfig(currentConfig);
        return true; // Indicates a change was detected
      }
    }
    return false; // No change detected
  };

  useEffect(() => {
    checkForChanges(projectConfig, previousConfig || {});
    setPreviousConfig(projectConfig);
  }, [JSON.stringify(projectConfig)]);
  // // +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  useEffect(() => {
    if (projectId && currentAccountId && !loaded) {
      const projectSource = `${process.env.REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/project.json`;

      const fetchData = async () => {
        try {
          const response = await fetch(projectSource);
          const data = await response.json();
          const { interactives } = data;
          dispatch({
            type: 'SET_ALL', payload: {
              ...data,
              interactives: splitOverlaysIntoSmallerArrays(interactives || [], 5)
            }
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }

        setLoaded(true)
      };

      fetchData();
    } else {
      // console.log('but we get here')
    }

  }, [isAuthorizedForAccount, projectId])

  useEffect(() => {
    videoElRef?.current?.eventRelay?.emit('update_from_studio', {
      update: 'src_update',
      data: getUpdateData()
    });
  }, [JSON.stringify(projectConfig?.video)])


  return (
    <ConfigContext.Provider
      value={{
        projectConfig,
        dispatch
      }}>
      {children}
    </ConfigContext.Provider>
  );
}

const useConfigContext = () => {
  return useContext(ConfigContext);
}

export default useConfigContext;