import React from 'react';
import { auth } from '../../../auth/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Button from '@mui/material/Button';
import { FcGoogle } from "react-icons/fc";
import SignInWithButton from 'components/user/SignInWithButton';


const GoogleSignIn = ({ setUserIsAuthorized }) => {
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
      })
      .then(() => {
        if (auth.currentUser) {
          setUserIsAuthorized(true)
        } else {
          alert('no user')
        }
      })
      .catch((error) => {
        // Handle Errors here.
        console.error(error);
      });
  };

  return (
    <SignInWithButton
      type="google"
      Icon={FcGoogle}
      onClick={signInWithGoogle}
    />
  );
}

export default GoogleSignIn;
