import React, { useState } from 'react';
import { Button, Typography, Box, Tabs, Tab } from '@mui/material';
import './metadata_tab.scss';
import GenerateTab from '../GenerateTab';
import MetadataTypeTab from '../MetadataTypeTab';


const MetadataTab = ({ mp4File }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className="metadata-tab-container">
      <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab className="sub-tab" label="Generate" />
        <Tab className="sub-tab" label="NSFW" />
        <Tab className="sub-tab" label="Video text" />
        <Tab className="sub-tab" label="Label Detection" />
        <Tab className="sub-tab" label="transcript" />
      </Tabs>

      {selectedTab === 0 && (
        <Box className="tab-content">
          <GenerateTab setSelectedTab={setSelectedTab} />
        </Box>
      )}
      {/* ultimately, the data that is provided from the config will determine which tabs are rendered. */}
      {selectedTab === 1 && (
        <Box className="tab-content">
          <MetadataTypeTab
            metadataType={'problematic_content'}
            urlFieldLabel="NSFW data url"
          />
        </Box>
      )}
      {selectedTab === 2 && (
        <Box className="tab-content">
          <MetadataTypeTab
            metadataType={'video_text'}
            urlFieldLabel="Video Text Raw Data Url"
          />
        </Box>
      )}
      {selectedTab === 3 && (
        <Box className="tab-content">
          <MetadataTypeTab
            metadataType='label_detection'
            urlFieldLabel="Label Detection Raw Data Url"
          />
        </Box>
      )}
      {selectedTab === 4 && (
        <Box className="tab-content">
          <MetadataTypeTab
            metadataType='transcript'
            urlFieldLabel="Transcript Raw Data Url"
          />
        </Box>
      )}
    </Box>
  );
};

export default MetadataTab;
