import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;

export const optimizeNSFWContent = async (currentAccountId) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey, projectId } = getQueryParams(params, ['apiKey', 'projectId']);

  const queryParams = new URLSearchParams({
    operation: 'optimize',
    optimizer_type: 'problematic_content',
    fileUri: `https://intrprtr.s3.amazonaws.com/accounts/${currentAccountId}/projects/${projectId}/processing/problematic_content/results.json`,
    results_json: `https://intrprtr.s3.amazonaws.com/accounts/${currentAccountId}/projects/${projectId}/optimizers/problematic_content/results.json`,
    apikey: apiKey,
    fileUriIsArray: false,
    projectId
  })

  try {
    console.log('problematic content optimization starting');
    // const invokeNSFWResponse = await fetch(`https://8p9gaic31e.execute-api.us-east-1.amazonaws.com/default/CompositorClientLambda?operation=optimize&optimizer_type=problematic_content&apikey=${apiKey}&fileUri=${fileUri}&results_json=https://intrprtr.s3.amazonaws.com/accounts/${currentAccountId}/projects/${projectId}/optimizers/problematic_content/results.json`);
    const invokeNSFWResponse = await fetch(`https://8p9gaic31e.execute-api.us-east-1.amazonaws.com/default/CompositorClientLambda?${queryParams.toString()}`);
    const responseJson = await invokeNSFWResponse.json();
    return invokeNSFWResponse;
  } catch (error) {
    console.error(error)
  }
};

