import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

// Create a context for the language
const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    const [lang, setLang] = useState('en')
    
    // Function to get current language from ref
    const getCurrentLanguage = () => {
        return lang
    };

    // Function to set language into the ref
    const setCurrentLanguage = (newLang) => {
        setLang(newLang)
    };

    return (
        <LanguageContext.Provider value={{ getCurrentLanguage, setCurrentLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

const useLanguage = () => {
    const context = useContext(LanguageContext);
    if (!context) {
        throw new Error('useLanguage must be used within a LanguageContextProvider');
    }
    return context;
};

export default useLanguage;
