import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { Navigate, useLocation } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

const PrivateRoute = ({ children }) => {
  const { currentUser, isAuthorizedForAccount, loading } = useAuthContext();
  const location = useLocation();

  if (loading) {
    return <LinearProgress style={{ position: 'fixed', width: '100%', zIndex: 9999 }} />;
  }

  const getDestination = () => {
    if (!currentUser) {
      return '/signin';
    }

    if (!isAuthorizedForAccount) {
      return '/error';
    }
  };

  const condition = currentUser && isAuthorizedForAccount;

  return condition ? children : <Navigate to={getDestination()} replace state={{ from: location }} />;
};

export default PrivateRoute;
