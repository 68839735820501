export const splitOverlaysIntoSmallerArrays = (array, interval) => {
  if (array.length === 0) return [];
  
  const smallerArrays = [];
  let startTime = 0;
  let endTime = startTime + interval;

  while (startTime <= array[array?.length - 1].startTime) {
    const smallerArray = array.filter(
      (item) => item.startTime >= startTime && item.startTime < endTime
    );

    smallerArrays.push(smallerArray);
    startTime = endTime;
    endTime += interval;
  }

  return smallerArrays;
};