import { db } from "auth/firebase";
import { doc, setDoc } from "firebase/firestore";

export const addUserRecord = async (user) => {
  const [firstName = '', lastName = ''] = user.displayName?.split(' ') || 'first last'.split(' ');
  // setDoc will either update or create new records. In this case we're using it to include the use of the existing firebase auth uid as the key for a db user record. 
  const addRecord = await setDoc(doc(db, 'user', user.uid), {
    first_name: firstName || '',
    last_name: lastName || '',
    email: user.email,
    id: user.uid
  })
};
