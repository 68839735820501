import React from 'react';
import { Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { languageSelectorButtonCss } from './materialUIStyleOverrides';

const ButtonWithStyles = withStyles({
  root: {
    flex: '4',
    backgroundColor: '#575555 !important',
    color: '#C1BCBC !important',
    borderRadius: '0 !important',
    color: '#C1BCBC !important',
    fontSize: '25px !important',
    boxShadow: 'none !important',

    '&:hover': {
      '&:not(.selected)': {
        backgroundColor: '#979797 !important',
        color: '#010101 !important'
      }
    },
    '&.selected': {
      backgroundColor: '#161515 !important'
    }
  }
})(Button)

const VideoLanguageButton = ({ label, isSelected, handleChooseSampleVideo, index }) => {
  
  return (
    <ButtonWithStyles
      variant="contained"
      color="primary"
      onClick={() => {
        handleChooseSampleVideo(index)
      }}
      classes={{
        root: isSelected ? 'selected' : ''
      }}
      style={languageSelectorButtonCss}
    >
      {label}
    </ButtonWithStyles>
  );
};

export default VideoLanguageButton;
