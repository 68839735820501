import { generateUUID } from 'utils/generateUUID';

export const defaultState = {
  video: {
    mp4: '',
    hls: '',
    srcName: ''
  },
  video_metadata: {
    optimizations: {
      problematic_content: {
        src: '',
        results: {}
      },
      video_text: {
        src: '',
        results: []
      },
      transcript: {
        src: '',
        results: []
      },
      label_detection: {
        src: '',
        results: []
      },
      meta_optimization: {
        src: '',
        rawResultsExample: {}
      }
    },
    problematic_content: {
      src: '',
      rawResultsExample: {},
      processStarted: false,
      processCompleted: false
    },
    video_text: {
      src: '',
      rawResultsExample: {},
      processStarted: false,
      processCompleted: false
    },
    transcript: {
      src: '',
      rawResultsExample: {},
      processStarted: false,
      processCompleted: false
    },
    label_detection: {
      src: '',
      rawResultsExample: {},
      processStarted: false,
      processCompleted: false
    }
  },
  moi_request_responses: [],
  interactives: [],
  id: generateUUID(),
  project: {
    title: '',
    description: '',
    createdate: Date.now(),
  }
};