import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import useAuthContext from "providers/AuthContextProvider/AuthContextProvider";
import useConfigContext from "providers/ConfigContextProvider/ConfigContextProvider";
import useS3Ping from "utils/aws/useS3Ping";
import { getAreProcessesFinished, numberOfProcessStarted } from "utils/areProcessesFinished";
import { invokeProcessAndStartPinging } from "utils/invokeProcessAndStartPinging";

const QuickStartButtons = ({
  videoUploaded,
  videoStaged,
  uploadVideo,
  handleSetFile,
  loading
}) => {
  const { dispatch, projectConfig = {} } = useConfigContext();
  const { startPinging } = useS3Ping({ dispatch });
  const { currentAccountId } = useAuthContext();
  const { video, video_metadata = {} } = projectConfig || {};

  const handleProcess = () => {
    invokeProcessAndStartPinging({
      startPinging,
      videoFile: video.mp4,
      selectedOptions: null, // default to all
      currentAccountId,
      dispatch
    })
  };

  const renderButton = () => {
    const processStartedAndFinished = numberOfProcessStarted(video_metadata) === 4 && getAreProcessesFinished(video_metadata);
    const processNotStarted = !numberOfProcessStarted(video_metadata);

    if (processStartedAndFinished) {
      return <Button variant='contained' onClick={() => { }}>Optimize</Button>
    }
    if (videoUploaded && processNotStarted) {
      return <Button variant="contained" onClick={handleProcess}>Process</Button>
    }

    if (!videoUploaded && videoStaged) {
      return <Button variant="contained" onClick={uploadVideo}>Upload Video</Button>
    }

    if (!videoUploaded && !videoStaged) {
      return (
        <Button
          variant="contained"
          component="label"
        >
          Select Video
          <input type="file" hidden onChange={handleSetFile} />
        </Button>
      )
    }
  };

  const renderContent = () => {
    const processesAreFinished = getAreProcessesFinished(video_metadata);
    const processesStarted = numberOfProcessStarted(video_metadata);
    if (loading || (processesStarted && !processesAreFinished)) {
      return <CircularProgress />;
    }

    return renderButton();
  }

  return (
    <div className="button-wrapper">
      {renderContent()}
    </div>
  )
}

export default QuickStartButtons;
