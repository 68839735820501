import { Button, } from '@mui/material';
import MetadataTypeCheckboxGroup from '../MetadataTypeCheckboxGroup';
import OptimizationStep from '../OptimizationStep';

const GenerateSteps = ({
  completedProcesses,
  currentAccountId,
  handleCheckboxChange,
  isPinging,
  onGenerate,
  onOptimize,
  onOptimizeAll,
  processTypes,
  projectId,
  videoMetadata
}) => {
  const shouldDisableGenerateButton = completedProcesses?.length === processTypes?.length;
  const shouldDisableReduce = !!videoMetadata?.optimizations?.meta_optimization?.src;
  const copyReducedData = async () => {
    const { REACT_APP_INTRPRTR_S3_PATH } = process.env;

    try {
      const fetchUrl = `${REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/optimizers/meta_optimizer/results.json`;
      const response = await fetch(fetchUrl);
      const responseJson = await response.json();
      navigator.clipboard.writeText(JSON.stringify(responseJson));
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="form">
      <div className="form-container">
        <h3 className="step-heading generate-step-heading">1. Generate</h3>
        <p>
          Select the metadata you would like to generate.
        </p>
        <div>
          {isPinging && (
            <div>Processing... </div>
          )}
        </div>
        <MetadataTypeCheckboxGroup
          options={processTypes}
          onCheckboxChange={handleCheckboxChange}
          videoMetadata={videoMetadata}
        />
        <div className="generate-metadata-button">
          <Button variant="contained" disabled={shouldDisableGenerateButton} onClick={() => onGenerate()}>
            {shouldDisableGenerateButton ? 'Step Complete' : 'Generate'}
          </Button>
        </div>
      </div>
      <OptimizationStep
        onClick={onOptimize}
        optimizations={videoMetadata?.optimizations}
      />
      <div className="form-container form-optimize-step">
        <div className="step-heading-wrapper">
          <h3 className="step-heading optimize-step-heading">
            3. Reduce Optimization
          </h3>
          <p>
            Combine optimizations to one, smaller dataset that can easily be ingested by your AI model
          </p>
        </div>
        <div className="right-justified-button-wrapper">
          <div>
            <Button
              disabled={shouldDisableReduce}
              variant="contained"
              onClick={onOptimizeAll}
            >
              {shouldDisableReduce ? 'Step Complete' : 'Reduce'}
            </Button>
          </div>
        </div>
      </div>
      <div className="form-container form-optimize-step">
        <div className="step-heading-wrapper">
          <h3 className="step-heading optimize-step-heading">
            4. Copy and Paste
          </h3>
          <p>
            Add a step to easily access data to copy and paste
          </p>
        </div>
        <div className="right-justified-button-wrapper">
          <div>
            <Button onClick={copyReducedData} variant="contained">
              copy
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default GenerateSteps;
