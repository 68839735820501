import React from 'react';
import { InputBase, Paper } from '@mui/material';
import { IoIosSearch } from "react-icons/io";
import './search_bar.scss';

const SearchBarWithOverlay = ({ isLoading }) => {
  return (
    <div className="search-bar-root">
      <Paper component="form" className="search-bar-container">
        <IoIosSearch className="search-icon" />
        <InputBase
          className="search-input"
          placeholder="Search..."
          inputProps={{ 'aria-label': 'search' }}
        />
      </Paper>
      {<div className="search-overlay"></div>}
    </div>
  );
};

export default SearchBarWithOverlay;
