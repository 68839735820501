import { useNavigate } from 'react-router-dom';
import { updateProjectState } from './aws/updateProjectState';
import { getQueryParams } from './getQueryParams';

// Check Here for project config and if the url path contains the project Id. If a config exists and there is no query string for projectId it should be appended to the URL.

const useDetermineProjectStatusAndUpdateUrl = () => {
  const params = new URLSearchParams(window.location.search)
  const { projectId } = getQueryParams(params, ['projectId']);
  const navigate = useNavigate();

  const checkAndUpdatePath = ({ projectConfig, currentAccountId }) => {
    const updatePath = () => {
      params.set('projectId', projectConfig.id);
      navigate(`?${params}`, { replace: true })
    }

    const projectInitiatedButNotSavedToS3 = projectConfig && !projectId;
    if (projectId && projectConfig) {
      console.log('projectConfig and Id exist', projectConfig)
    }

    if (projectId && !projectConfig) {
      console.log('no projectConfig but Id exists', projectConfig)
      // In this case the context Provider will fetch the contents.
    }

    if (projectInitiatedButNotSavedToS3) {
      updatePath();
      updateProjectState(projectConfig, currentAccountId);
    }
  }

  return { checkAndUpdatePath }
}

export default useDetermineProjectStatusAndUpdateUrl;