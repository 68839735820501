import { Button } from '@mui/material';
import { processTypes } from 'constants/processTypes';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';

const OptimizationStep = ({ onClick, optimizations }) => {
  const { currentAccountId } = useAuthContext();
  const { projectConfig } = useConfigContext();

  const completedOptimizations = Object.keys(optimizations).filter(opt => {
    if (opt === 'meta_optimization') return false;

    return !!optimizations[opt].src;
  });

  const shouldDisableOptimizeButton = completedOptimizations.length === processTypes.length;
  const getDataUrl = (processType) => {
    const baseUrl = `https://intrprtr.s3.amazonaws.com/accounts/${currentAccountId}/projects/${projectConfig.id}/optimizers`;
    if (processType === 'transcript') {
      return `${baseUrl}/transcription/results.json`
    }

    return `${baseUrl}/${processType}/results.json`
  }
  return (
    <div className="form-container form-optimize-step">
      <div className="step-heading-wrapper">
        <h3 className="step-heading optimize-step-heading">2. Optimize</h3>
        <p>
          Optimize data for fast responses
        </p>
        <div>
          <div className="completed-optimizations">completed optimizations</div>
          {completedOptimizations ? completedOptimizations.map(c => {
            const process = processTypes.find(p => p.value === c);
            return (
              <span className='optimize-label'>
                <a href={getDataUrl(process.value)} target="_blank">
                  {process.name}
                </a>
                {' | '}
              </span>
            )
          }) : 'none'}
        </div>
      </div>
      <div className="right-justified-button-wrapper">
        <Button disabled={shouldDisableOptimizeButton} variant="contained" onClick={onClick}>
          {shouldDisableOptimizeButton ? 'Step Complete' : 'Run Optimize'}
        </Button>
      </div>
    </div>
  )
}

export default OptimizationStep;