import { Button } from "@mui/material"

const Admin = () => {
  const onClick = async () => {
    const response = await fetch('https://8p9gaic31e.execute-api.us-east-1.amazonaws.com/default/CompositorClientLambda/auth');
    console.log(response);
  };

  return <Button onClick={onClick} variant="contained">Send Auth Request</Button>;
}

export default Admin