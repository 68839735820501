import React, { useEffect, useState } from 'react';
import { TextField, Button, Paper, Typography, Select, MenuItem } from '@mui/material';
import './moi_prompt_and_response.scss';
import { invokeMOIPromptRequest } from 'utils/aws/invokeMOIPromptRequest';
import useS3Ping from 'utils/aws/useS3Ping';
import { reducerActions } from 'constants/reducerActions';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { modelTypes } from 'constants/modelTypes';
import { serviceTypesAndPrompts } from 'constants/serviceTypes';
import MOISelect from './MOISelect';
import ReactMarkdown from 'react-markdown';

const MOIPromptAndResponse = () => {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [manualReviewOfResponse, setManualReviewOfResponse] = useState(false);
  const [model, setModel] = useState('');
  const [service, setService] = useState(''); // Assuming an array of model names
  const { projectConfig, dispatch } = useConfigContext();
  const { startPinging } = useS3Ping({ dispatch });
  const { currentAccountId } = useAuthContext();
  const { SET_MOI_PROMPT_REQUEST_RESULTS } = reducerActions;

  const handleInputChange = (e) => {
    setResponse('');
    setPrompt(e.target.value);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value);
  };

  const handleServiceChange = (event) => {
    setService(event.target.value);

    if (service !== 'freeform') {
      const servicePrompt = serviceTypesAndPrompts[event.target.value].prompt;
      setPrompt(servicePrompt)
    }
  };

  const handlePreviousResponseChange = (event) => {
    setManualReviewOfResponse(true);
    setResponse(event.target.value)
  };

  const handleSubmit = async () => {
    const MOISessionId = Date.now();
    setManualReviewOfResponse(false);
    setLoading(true);
    try {
      const res = await invokeMOIPromptRequest({ prompt, model, MOISessionId, currentAccountId });
      startPinging({
        action: SET_MOI_PROMPT_REQUEST_RESULTS,
        interval: 1000,
        delay: 1000,
        moi_data: {
          MOISessionId,
          serviceType: service
        },
        maxAttempts: 900
      });
    } catch (error) {
      console.error('Error fetching response:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectConfig.moi_request_responses) {
      const { moi_request_responses } = projectConfig;

      if (!manualReviewOfResponse && moi_request_responses.length) {
        const currentResponse = moi_request_responses?.slice(-1)[0].result.choices[0].text || 'no request made';
        setResponse(currentResponse);
      }
    }

  }, [JSON.stringify(projectConfig)]);

  const renderServiceOptions = () => {
    return Object.keys(serviceTypesAndPrompts).map((service, index) => {
      const serviceLabel = serviceTypesAndPrompts[service].label;

      return (
        <MenuItem key={index} value={service}>{serviceLabel}</MenuItem>
      )
    })
  }

  const renderModelOptions = () => {
    return modelTypes.map((model, index) => (
      <MenuItem key={index} value={model}>{model}</MenuItem>
    ))
  }

  const renderPreviousChoiceOptions = () => {
    const { moi_request_responses } = projectConfig;
    const resultsLength = moi_request_responses.length;

    return moi_request_responses.map((response, index) => {
      return <MenuItem key={index} value={response.result.choices[0].text}>{`prompt no. ${((index + 1) + resultsLength) - resultsLength}: ${response.serviceType}`}</MenuItem>
    });
  }

  const renderTextField = () => {
    return (
      <TextField
        label="Enter your prompt"
        variant="outlined"
        fullWidth
        value={prompt}
        onChange={handleInputChange}
        multiline
        rows={4}
        className="prompt-input"
      />
    )
  }

  return (
    <Paper className="moi-container">
      <MOISelect
        value={model}
        onChange={handleModelChange}
        className="select model-select"
        options={renderModelOptions()}
        label="select a model"
      />
      <MOISelect
        value={service}
        onChange={handleServiceChange}
        className="select service-select"
        options={renderServiceOptions()}
        label="select a service"
      />
      <MOISelect
        value={service}
        onChange={handlePreviousResponseChange}
        className="select service-select"
        options={renderPreviousChoiceOptions()}
        label="review previous prompts"
        disabled={!projectConfig.moi_request_responses.length}
      />
      {service && service === 'freeform' && renderTextField()}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading || !model}
        className="submit-button"
      >
        {loading ? 'Loading...' : 'Submit'}
      </Button>
      {/* <Typography variant="h6" className="response-title">User Prompt:</Typography>
      <Typography variant="body1" className="response-text">{prompt}</Typography> */}
      <Typography variant="h6" className="response-title">Response:</Typography>
      <Typography variant="body1" className="response-text">
        <ReactMarkdown>
          {response}
        </ReactMarkdown>
      </Typography>
    </Paper>
  );
};

export default MOIPromptAndResponse;
