import './generate_tab.scss';
import React, { useEffect, useState } from 'react';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import useS3Ping from 'utils/aws/useS3Ping';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { processTypes } from 'constants/processTypes';
import { invokeProcessAndStartPinging } from 'utils/invokeProcessAndStartPinging';
import { invokeOptimizersAndStartPinging } from 'utils/aws/optimizers/invokeOptimizersAndStartPinging';
import { invokeGlobalOptimizer } from 'utils/aws/optimizers/invokeGlobalOptimizer';
import { reducerActions } from 'constants/reducerActions';
import GenerateSteps from '../GenerateSteps';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const { SET_META_OPTIMIZATION_RESULTS } = reducerActions;

const GenerateTab = ({ setSelectedTab }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [completedProcesses, setCompletedProcesses] = useState(null)
  const [processing, setProcessing] = useState(false);
  const { projectConfig = {}, dispatch } = useConfigContext();
  const { video = {}, id: projectId, video_metadata } = projectConfig;
  const { startPinging, isPinging } = useS3Ping({ dispatch });
  const { currentAccountId } = useAuthContext();

  const handleCheckboxChange = (event) => {
    if (event.target.checked) {
      setSelectedOptions([...selectedOptions, event.target.value]);
    } else {
      setSelectedOptions(selectedOptions.filter(option => option !== event.target.value));
    }
  };

  const onGenerate = () => {
    invokeProcessAndStartPinging({
      startPinging,
      videoFile: video?.mp4,
      selectedOptions,
      currentAccountId,
      dispatch
    })
  };

  const onOptimize = () => {
    invokeOptimizersAndStartPinging({
      startPinging,
      completedProcesses,
      currentAccountId
    })
  };

  const getSourceFilesForMetaOptimization = () => {
    const { video_metadata } = projectConfig;
    const { optimizations } = video_metadata;
    const optimizersWithSources = Object.keys(optimizations).filter(optimization => optimization !== 'meta_optimization' && !!optimizations[optimization].src)
    const sourceFiles = optimizersWithSources.map(o => (optimizations[o].src));

    return sourceFiles;
  }

  const onOptimizeAll = () => {
    const answer = window.confirm('running optimize all will completely replace data generated in previous runs, do you want to continue?')

    if (answer) {
      const sourceFilesForOptimization = getSourceFilesForMetaOptimization();
      invokeGlobalOptimizer(sourceFilesForOptimization, currentAccountId);
      startPinging({ action: SET_META_OPTIMIZATION_RESULTS })
    }
  }

  useEffect(() => {
    if (projectConfig?.video_metadata) {
      const completedJobs = Object.keys(projectConfig.video_metadata).filter(processType => {
        return projectConfig.video_metadata[processType].processCompleted;
      });

      if (completedJobs.length) {
        setCompletedProcesses(state => {
          if (state) {
            // The block in side the setTimeout sends user to the tab for the process that was just completed. 
            setTimeout(() => {
              const newProcess = completedJobs.filter(job => {
                return !state.includes(job);
              })[0];

              if (newProcess) {
                const newProcessTabNumber = processTypes.find(option => option.value === newProcess).tabNumber;
                setSelectedTab(newProcessTabNumber)
              }

            }, 1000)
          }

          return completedJobs;
        });
      } else {
        setCompletedProcesses([]);
      }
    }
  }, [JSON.stringify(projectConfig)]);

  useEffect(() => {
    const { video_metadata } = projectConfig;
    const inProgressProcesses = Object.keys(video_metadata).filter(type => {
      const currentType = video_metadata[type];
      if (currentType === 'optimizations') {
        return false;
      };

      return currentType.processStarted && !currentType.processCompleted;
    })

    if (inProgressProcesses.length) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }

  }, [JSON.stringify(projectConfig.video_metadata)])

  return (
    <div className="sub-tab-container">
      <div className="generate-tab-heading-and-spinner">
        <h2 className="generate-tab-heading">
          <label>
            Video Metadata
          </label>
        </h2>
        {processing && (
          <Box
            className="loading-spinner"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around'
            }}>
            <CircularProgress sx={{ color: 'red' }} color="secondary" />
          </Box>
        )}
      </div>
      <GenerateSteps
        completedProcesses={completedProcesses}
        currentAccountId={currentAccountId}
        handleCheckboxChange={handleCheckboxChange}
        isPinging={isPinging}
        onGenerate={onGenerate}
        onOptimize={onOptimize}
        onOptimizeAll={onOptimizeAll}
        processTypes={processTypes}
        projectId={projectId}
        videoMetadata={video_metadata}
      />
    </div>
  );
};

export default GenerateTab;
