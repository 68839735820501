import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { FiSettings } from 'react-icons/fi';

import './translation_settings_button.scss';

const TranslationSettingsButton = () => {
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (event, value) => {
    setSelectedLanguage(value);
  };

  const handleAddLanguage = () => {
    if (selectedLanguage) {
      setSelectedLanguages((prevLanguages) => [
        ...prevLanguages,
        selectedLanguage,
      ]);
      setSelectedLanguage(null);
    }
  };

  const handleRemoveLanguage = (language) => {
    setSelectedLanguages((prevLanguages) =>
      prevLanguages.filter((item) => item !== language)
    );
  };

  const handleTranslate = () => {
    // Perform translation logic here
    handleClose();
  };

  useEffect(() => {
    handleAddLanguage()
  }, [selectedLanguage])

  
  const languages = [
    { label: 'English', code: 'en' },
    { label: 'Spanish', code: 'es' },
    { label: 'French', code: 'fr' },
    { label: 'German', code: 'de' },
    { label: 'Italian', code: 'it' },
    { label: 'Portuguese', code: 'pt' },
    { label: 'Russian', code: 'ru' },
    { label: 'Chinese', code: 'zh' },
    { label: 'Japanese', code: 'ja' },
    { label: 'Korean', code: 'ko' },
    // Add more languages as needed
  ];

  return (
    <>
      <Tooltip title="Open Translation Manager" placement="top">
        <Button
          disableRipple
          className="translation-settings-button"
          startIcon={<FiSettings className="translation-settings-button-icon" />}
          onClick={handleOpen}
        ></Button>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        className="modal"
        aria-labelledby="translation-settings-modal"
        aria-describedby="translation-settings-modal-description"
      >
        <div className="modal-content">
          <h2 id="translation-settings-modal" className="header">
            Translation Settings
          </h2>
          <Autocomplete
            options={languages}
            getOptionLabel={(option) => option.label}
            value={selectedLanguage}
            onChange={handleLanguageChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Language"
                variant="outlined"
                className="dropdown"
              />
            )}
          />
          <div className="language-list">
            {selectedLanguages.map((language) => (
              <Chip
                key={language.code}
                label={`${language.label} - ${language.code}`}
                onDelete={() => handleRemoveLanguage(language)}
                className="language-chip"
              />
            ))}
          </div>
          <div className="button-container">
            <Button variant="contained" onClick={handleTranslate} className="translate-button">
              Translate
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className="cancel-button"
              style={{ backgroundColor: '#d24c4c', color: '#fff' }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TranslationSettingsButton;