import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'utils/getQueryParams';

const useS3Ping = ({ dispatch }) => {
  const [isPinging, setIsPinging] = useState(false);
  const [fileExists, setFileExists] = useState(false);
  const [inProgress, setInProgress] = useState([]);
  const { currentAccountId } = useAuthContext();

  const params = new URLSearchParams(useLocation().search);
  const { projectId } = getQueryParams(params, ['projectId']);
  const baseUrl = `${process.env.REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}`;

  const typeToUrlMapping = (propName, MOISessionId) => {
    const map = {
      'problematic_content': `/projects/${projectId}/processing/problematic_content/results.json`,
      'video_text': `/projects/${projectId}/processing/text_extraction/results.json`,
      'transcript': `/projects/${projectId}/processing/transcription/results.json`,
      'label_detection': `/projects/${projectId}/processing/label_detection/results.json`,
      'moi_prompt_request': `/projects/${projectId}/moi_sessions/${MOISessionId}/results.json`,
      'transcript_optimizer': `/projects/${projectId}/optimizers/transcription/results.json`,
      'label_detection_optimizer': `/projects/${projectId}/optimizers/label_detection/results.json`,
      'video_text_optimizer': `/projects/${projectId}/optimizers/text_extraction/results.json`,
      'problematic_content_optimizer': `/projects/${projectId}/optimizers/problematic_content/results.json`,
      'meta_optimizer': `/projects/${projectId}/optimizers/meta_optimizer/results.json`
    }

    return map[propName]
  };

  const getSampleResults = (type, response, moi_data) => {
    const serviceType = moi_data.serviceType || null;

    if (type === 'problematic_content' || type === 'label_detection') {
      return response[0];
    }

    if (type === 'transcript') {
      return {
        itemSample: response.results?.items[0],
        transcript: response.results?.transcripts[0]
      };
    }

    if (type === 'video_text') {
      return response[0].TextDetections[0] || {};
    }

    if (type === 'moi_prompt_request') {
      return { ...JSON.parse(response.body), serviceType: serviceType }
    }

    if (type === 'problematic_content_optimizer') {
      return response
    }

    if (type === 'label_detection_optimizer') {
      return response;
    }

    if (type === 'video_text_optimizer') {
      return response;
    }

    if (type === 'transcript_optimizer') {
      return response;
    }

    if (type === 'meta_optimizer') {
      return response;
    }
  };

  const startPinging = ({
    action,
    interval = 1000,
    delay = 2000,
    maxAttempts = 100000,
    moi_data = {}
  }) => {
    let attempts = 0;
    let intervalId;
    const url = baseUrl + typeToUrlMapping(action.propName, moi_data.MOISessionId);

    if (!url) {
      console.error('Invalid type provided to useS3Ping');
      return;
    }

    const pingUrl = async () => {
      const cachBusterUrl = url + `?v=${attempts}`;

      try {
        const response = await fetch(cachBusterUrl);

        if (response.ok) {
          setIsPinging(false);
          clearInterval(intervalId);
          setFileExists(true);

          const responseJson = await response.json();
          const rawResultSample = getSampleResults(action.propName, responseJson, moi_data);

          dispatch({
            type: action.actionName,
            payload: {
              src: url,
              rawResultsExample: rawResultSample
            }
          });
        } else {
          throw new Error('non-200 response');
        }
      } catch (error) {
        if (error) {
          console.warn('Source not availabe checking again...');
          console.warn(error)
        }
        if (attempts < maxAttempts) {
          attempts += 1;
        } else {
          setIsPinging(false);
          clearInterval(intervalId);
          // Handle failure, perhaps update reducer with failure status
          // dispatch({ type: 'UPDATE_STATUS', payload: { url, status: 'failure' } });
        }
      }
    };

    setTimeout(() => {
      setIsPinging(true);
      intervalId = setInterval(pingUrl, interval);
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  };

  useEffect(() => {
    if (fileExists) {
      console.log('useEffect IT LIVESS');
    }
  }, [fileExists]);

  return { startPinging, isPinging };
};

export default useS3Ping;
