import './translation_table.scss'
import { useEffect, useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import useConfigContext from '../../providers/ConfigContextProvider/ConfigContextProvider';
import { getQueryParams } from '../../utils/getQueryParams';
import TranslationTableRows from './TranslationTableRows/TranslationTableRows';
import TranslationTableHeader from './TranslationTableHeader/TranslationTableHeader';
import useLanguage from 'providers/LanguageContextProvider/LanguageContextProvider';

const TranslationTable = ({ rawInteractives, setSelectedLanguage, selectedLanguage }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const { dispatch } = useConfigContext();
  const { projectConfig } = useConfigContext();
  const { interactives = []} = projectConfig;
  
  const getContent = (i) => {
    if (selectedLanguage && typeof i.content === 'object') {
      return i.content[selectedLanguage];
    }

    return i.content;
  }
  
  const renderPaginationNumbers = () => {
    const offSet = pageIndex % 5
    const startIndex = pageIndex - offSet;
    const endIndex = startIndex + 5;
    const paginationNumbers = [];

    for (let i = startIndex; i < endIndex; i++) {
      if (i < interactives.length) {
        paginationNumbers.push(
          <div
            key={i}
            className={`pagination-number ${
              i === pageIndex ? "active" : ""
            }`}
            onClick={() => handlePaginationClick(i)}
          >
            {i + 1}
          </div>
        );
      }
    }

    return paginationNumbers;
  };

  const handlePaginationClick = (index) => {
    setPageIndex(index);
  };

  const handleBackClick = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const handleForwardClick = () => {
    if (pageIndex < interactives.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  return (
    <>
      <TranslationTableHeader 
        setSelectedLanguage={setSelectedLanguage} 
        selectedLanguage={selectedLanguage}
      />
        <TableContainer className='translation-table'>
          <Table>
            <TableBody className='translation-table-body'>
              <TranslationTableRows 
                interactiveArrays={interactives}
                pageIndex={pageIndex}
                getContent={getContent}
                dispatch={dispatch}
                lang={selectedLanguage}
              />
            </TableBody>
          </Table>
        </TableContainer>
        <div className="pagination-container">
          <div className="pagination-arrows">
            <div className="pagination-arrow" onClick={handleBackClick}>
              &lt;
            </div>
            <div className="pagination-numbers">{renderPaginationNumbers()}</div>
            <div className="pagination-arrow" onClick={handleForwardClick}>
              &gt;
            </div>
          </div>
        </div>
    </>
  )
}

export default TranslationTable;
