import React, { useEffect, useState, createContext, useContext } from 'react';
import { auth } from '../../auth/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkForUserAndUpdateDb } from 'utils/auth/checkForUserAndUpdateDb';
import { authorizeAccount } from 'utils/auth/authorizeAccount';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentAccountId, setCurrentAccountId] = useState(null);
  const [isAuthorizedForAccount, setIsAuthorizedForAccount] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);

      if (currentUser) {
        // Check for user record in DB and update if does not exist
        checkForUserAndUpdateDb({ user });
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [currentUser]);

  useEffect(() => {
    if (currentUser && location.state) {
      navigate(location.state.from.pathname + location.state.from.search)
    }

    if (currentUser && !currentAccountId && window.location.pathname !== '/') {
      authorizeAccount({ setCurrentAccountId, setIsAuthorizedForAccount, currentUser, setLoading });
    }

  }, [currentUser, currentAccountId])

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        currentAccountId,
        setCurrentAccountId,
        isAuthorizedForAccount,
        loading
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  return useContext(AuthContext);
}

export default useAuthContext;