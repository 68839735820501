import './metadata_type_tab.scss';
import { Box, Typography } from "@mui/material";
import UrlField from "components/UiHelpers/UrlField";
import useConfigContext from "providers/ConfigContextProvider/ConfigContextProvider";
import { IoIosCopy } from "react-icons/io";

const MetadataTypeTab = ({ metadataType, urlFieldLabel }) => {
  const { projectConfig } = useConfigContext();
  const { video_metadata } = projectConfig;
  const currentData = video_metadata[metadataType];
  const { src, rawResultsExample } = currentData;

  const renderMetadata = () => {
    const formattedMetadata = JSON.stringify(rawResultsExample, null, 4);

    return (
      <Typography component="pre" className="metadata-pre">
        {formattedMetadata}
      </Typography>
    );
  };

  const handleCopySrcUrl = async (e) => {
    const copied = await navigator.clipboard.writeText(src);
    // add some toast alert saying that the text has been copied.
  }

  return (
    <div className="metadata-type-tab-container">
      <div className="metadata-type-info-block">
        <UrlField
          label={urlFieldLabel}
          value={src}
          iconComponent={IoIosCopy}
          iconClickHandler={handleCopySrcUrl}
        />
      </div>
      <Box className="metadata-display-container">
        <h4>
          <label>
            Instance JSON Example
          </label>
        </h4>
        {renderMetadata()}
      </Box>
    </div>
  );
}

export default MetadataTypeTab;
