import { useEffect, useState, useRef } from 'react';
import './logo.scss';
import { RxVideo } from 'react-icons/rx'
import CopyText from '../CopyText/CopyText';

const Logo = ({ handleOpen }) => {
  const [visible, setVisible] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef();
  useEffect(() => {
    setVisible(true)
  }, [])

  const classes = (className) => (`${className} ${visible ? 'visible' : ''}`)

  useEffect(() => {
    const { height } = containerRef.current?.getBoundingClientRect();
    if (containerRef.current && height) {
      setContainerHeight(height - 45)
    }
  }, [containerRef.current])

  const style = {
    height: containerHeight ? `${containerHeight}px` : null
  }

  return (
    <div id="logo-wrapper" ref={containerRef} className="logo-wrapper">
      <div className="logo-inner-wrapper">
        <div className="logo-second-inner-wrapper">
          <div className={classes('logo')}>
            <span className="logo-icon">
              <RxVideo />
            </span>
            intrprtr
          </div>
          <div className={classes('logo-slogan-text')}>
            Serverless AI Video
          </div>
        </div>
      </div>
      <CopyText handleOpen={handleOpen} />
    </div>

  );
};

export default Logo;
