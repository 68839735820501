import { useState, useEffect } from 'react';
import TranslationTable from '../../../TranslationTable/TranslationTable';
import './translation_editor.scss';
import LeftPanelTabs from '../../LeftPanelTabs';
import VideoDetails from '../../VideoDetails';
import useConfigContext from '../../../../providers/ConfigContextProvider/ConfigContextProvider';
import { getQueryParams } from 'utils/getQueryParams';
import useLanguage from 'providers/LanguageContextProvider/LanguageContextProvider';
import MetadataTab from 'components/Editor/MetadataTab';
import ModelPickerTab from 'components/Editor/ModelPickerTab';

const TranslationEditor = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsList = [
    'defaultLang'
  ];
  const { defaultLang } = getQueryParams(params, paramsList);
  const [activeTab, setActiveTab] = useState('video');
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLang || 'en');
  const [interactives, setInteractives] = useState();
  const { projectConfig } = useConfigContext();
  const { setCurrentLanguage, getCurrentLanguage } = useLanguage();


  const renderActiveTab = () => {
    if (activeTab === 'translate') {
      return (
        <div className='translation-text-editor-container'>
          <TranslationTable
            rawInteractives={interactives}
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </div>
      )
    }

    if (activeTab === 'video') {
      return (
        <div>
          <VideoDetails />
        </div>
      )
    }

    if (activeTab === 'metadata') {
      return <MetadataTab mp4File={projectConfig?.video?.mp4} />
    }

    if (activeTab === 'model-picker') {
      return <ModelPickerTab />
    }
  }

  useEffect(() => {
    if (selectedLanguage !== getCurrentLanguage()) {
      setCurrentLanguage(selectedLanguage)
    }
  }, [selectedLanguage])

  return (
    <div className='editor-left-panel-root'>
      <LeftPanelTabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
      <div className="panel-content-root">
        {renderActiveTab()}
      </div>
    </div>
  )
}

export default TranslationEditor;
