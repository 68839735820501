import React, { useEffect, useState } from "react";
import { Modal, Button } from "@mui/material";
import './home.scss';
import Logo from "./Logo/Logo";
import SampleVideoPreview from "./SampleVideoPreview/SampleVideoPreview";
import { stringToBoolean } from "../../utils/stringToBoolean";
import { getQueryParams } from "../../utils/getQueryParams";

const Home = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const params = new URLSearchParams(window.location.search)
  const { loadPreviewActive } = getQueryParams(params, ['loadPreviewActive']);
  
  useEffect(() => {
    if (stringToBoolean(loadPreviewActive)) {
      setModalOpen(true)
    }
  }, [])

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);

  };
  
  return (
    <div className="home-path-root">
      <Logo handleOpen={handleOpen} />
      {modalOpen && (
        <Modal
          open={modalOpen}
          onClose={handleClose}
          className="modal-with-close"
          fluid
        >
          <SampleVideoPreview />
        </Modal>
      )}
    </div>
  );
};

export default Home;
