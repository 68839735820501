import React from 'react';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import { Navigate, useLocation } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';
import { getUserIsAdmin } from 'utils/auth/getUserIsAdmin';

const AdminPrivateRoute = ({ children }) => {
  const { currentUser, loading } = useAuthContext();
  const location = useLocation();
  let isAdmin;

  if (currentUser) {
    isAdmin = getUserIsAdmin(currentUser.uid)
  }

  if (loading) {
    return <LinearProgress style={{ position: 'fixed', width: '100%', zIndex: 9999 }} />;
  }

  const getDestination = () => {
    if (!currentUser) {
      return '/signin';
    }

    if (currentUser.role !== 'admin') {
      return '/error';
    }
  };

  return isAdmin ? children : <Navigate to={getDestination()} replace state={{ from: location }} />;
};

export default AdminPrivateRoute;
