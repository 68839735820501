const { Select, MenuItem } = require("@mui/material")

const MOISelect = ({ value, label, options, onChange, className }) => {
  return (
    <Select
      value={value}
      onChange={onChange}
      displayEmpty
      className={className}
      inputProps={{ 'aria-label': 'Without label' }}
    >
      <MenuItem value="" disabled>
        {label}
      </MenuItem>
      {options}
    </Select>
  )
}

export default MOISelect;
