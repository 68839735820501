export const reducerActions = {
  SET_ALL: {
    actionName: 'SET_ALL'
  },
  SET_SRC: {
    actionName: 'SET_SRC'
  },
  SET_SRC_NAME: {
    actionName: 'SET_SRC_NAME'
  },
  SET_HLS_SRC: {
    actionName: 'SET_HLS_SRC'
  },
  DELETE_TEXT_ENTRY: {
    actionName: 'DELETE_TEXT_ENTRY'
  },
  SET_TITLE: {
    actionName: 'SET_TITLE',
    propName: 'title'
  },
  SET_DESCRIPTION: {
    actionName: 'SET_DESCRIPTION',
    propName: 'description'
  },
  SET_PROBLEMATIC_CONTENT_RESULTS: {
    actionName: 'SET_PROBLEMATIC_CONTENT_RESULTS',
    propName: 'problematic_content'
  },
  SET_VIDEO_TEXT_RESULTS: {
    actionName: 'SET_VIDEO_TEXT_RESULTS',
    propName: 'video_text'
  },
  SET_LABEL_DETECTION_RESULTS: {
    actionName: 'SET_LABEL_DETECTION_RESULTS',
    propName: 'label_detection'
  },
  SET_TRANSCRIPT_RESULTS: {
    actionName: 'SET_TRANSCRIPT_RESULTS',
    propName: 'transcript'
  },
  SET_TRANSCRIPT_OPTIMIZATION: {
    actionName: 'SET_TRANSCRIPT_OPTIMIZATION',
    propName: 'transcript_optimizer'
  },
  SET_LABEL_DETECTION_OPTIMIZATION: {
    actionName: 'SET_LABEL_DETECTION_OPTIMIZATION',
    propName: 'label_detection_optimizer'
  },
  SET_VIDEO_TEXT_OPTIMIZATION: {
    actionName: 'SET_VIDEO_TEXT_OPTIMIZATION',
    propName: 'video_text_optimizer'
  },
  SET_PROBLEMATIC_CONTENT_OPTIMIZATION: {
    actionName: 'SET_PROBLEMATIC_CONTENT_OPTIMIZATION',
    propName: 'problematic_content_optimizer'
  },
  SET_MOI_PROMPT_REQUEST_RESULTS: {
    actionName: 'SET_MOI_PROMPT_REQUEST_RESULTS',
    propName: 'moi_prompt_request'
  },
  SET_META_OPTIMIZATION_RESULTS: {
    actionName: 'SET_META_OPTIMIZATION_RESULTS',
    propName: 'meta_optimizer'
  },
  UPDATE_PROCESS_STARTED_STATE: {
    actionName: 'UPDATE_PROCESS_STARTED_STATE'
  }
}


// PropName is a placeholder for various needs to reference a label or prop name other than the event type constant
// 