import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;
export const invokeMOIPromptRequest = async ({ model, prompt, MOISessionId, currentAccountId }) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey, projectId } = getQueryParams(params, ['apiKey', 'projectId']);
  const { REACT_APP_INTRPRTR_S3_PATH, REACT_APP_INTRPRTR_COMPOSITOR_LAMBDA_GATEWAY } = process.env

  const queryParams = new URLSearchParams({
    apikey: apiKey,
    operation: 'mob_of_idiots',
    prompt,
    context_size: 50,
    cmd: 'run_model',
    model_name: model,
    results_json: `${REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/moi_sessions/${MOISessionId}/results.json`,
    fileUri: `${REACT_APP_INTRPRTR_S3_PATH}/accounts/${currentAccountId}/projects/${projectId}/optimizers/meta_optimizer/results.json`
  });
  console.log(REACT_APP_INTRPRTR_COMPOSITOR_LAMBDA_GATEWAY)
  const url = `${REACT_APP_INTRPRTR_COMPOSITOR_LAMBDA_GATEWAY}?${queryParams.toString()}`
  try {
    const invokeMOIPromptResponse = await fetch(url);
    console.log('MOI prompt request sent');

    const responseJson = await invokeMOIPromptResponse.json();
    return responseJson;
  } catch (error) {
    console.error('Error in invokeMOIPromptRequest:', error);
    throw error;
  }
};
