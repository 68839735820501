import './translation_table_language_dropdown.scss'
import React, { useState } from "react";
import { MenuItem, Select } from "@mui/material";

const languages = [
  {
    code: "fr",
    name: "French",
  },
  {
    code: "zh",
    name: "Mandarin",
  },
  {
    code: "es",
    name: "Spanish",
  },
  {
    code: 'en',
    name: 'english'
  }
];

const TranslationTableLanguageDropdown = ({ selectedLanguage, setSelectedLanguage }) => {
  return (
    <Select
      className="translation-dropdown"
      // label="Translated Language"
      value={selectedLanguage}
      onChange={(event) => setSelectedLanguage(event.target.value)}
    >
      {languages.map((language) => (
        <MenuItem
          className="translation-dropdown-option"
          key={language.code}
          value={language.code}
        >
          {language.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TranslationTableLanguageDropdown;
