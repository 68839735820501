export const stringToBoolean = (string) => {
  if (string === 'true') {
    return true
  }

  if (string === 'false') {
    return false;
  }
  
  console.warn('you cannot attempt to coerce a string that is not strictly equal to "true" or "false"')
}