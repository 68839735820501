import React from 'react';
import { FormGroup } from '@mui/material';
import MetadataCheckboxItem from '../MetadataCheckboxItem';

const MetadataTypeCheckboxGroup = ({ options, onCheckboxChange, videoMetadata }) => {

  return (
    <FormGroup className="checkboxes">
      {options.map(option => {
        const optionMetadata = videoMetadata[option.value] || {};
        const { processStarted = '', processCompleted = '' } = optionMetadata;
        return (
          <MetadataCheckboxItem
            key={option.name}
            name={option.name}
            label={option.label}
            value={option.value}
            onChange={onCheckboxChange}
            shouldDefaultOn={processStarted}
            shouldDefaultDisabled={processCompleted}
          />
        )
      })}
    </FormGroup>
  );
}

export default MetadataTypeCheckboxGroup;
