import './url_field.scss'
import { Button, InputAdornment, TextField } from "@mui/material";
import { ImUpload3 } from "react-icons/im";

const UrlField = ({
  label,
  processingMessage,
  variant,
  value,
  altValueMessage,
  disabled = false,
  fontSize = '40px',
  iconComponent,
  iconClickHandler = () => { alert('handler prop not provided') },
}) => {

  const iconStyle = {
    fontSize: fontSize
  };
  const Icon = iconComponent;

  return (
    <div className="url-field">
      <h4 className="source-field-label">
        <label>
          {label}
          <span className='transcoding-message'>{processingMessage}</span>
        </label>
      </h4>
      <TextField
        variant={variant}
        size="small"
        // disabled
        value={value || altValueMessage}
        className="video-details-input-field disabled-field"
        InputProps={{
          endAdornment: (
            <InputAdornment className="BASJAJSDBJASDFJASDF" position="end">
              <Button onClick={iconClickHandler} disabled={disabled} className='details-input-upload-button' variant='contained'>
                <Icon style={iconStyle} disabled className='details-input-upload-button-icon' />
              </Button>
            </InputAdornment>
          ),
          className: 'field-source-value',
        }}
      />
    </div>
  )
}

export default UrlField;