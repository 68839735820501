import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;

export const uploadVideoFile = async (videoFile, id, setLoadingMessage, currentAccountId) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey } = getQueryParams(params, ['apiKey']);
  const auth = await fetch(`https://b0dyd33dl4.execute-api.us-east-1.amazonaws.com/default/get_signed_token?apikey=${apiKey}`);
  const authData = await auth.json();

  AWS.config.credentials = {
    accessKeyId: authData.AccessKeyId,
    secretAccessKey: authData.SecretAccessKey,
    sessionToken: authData.SessionToken,
  };

  AWS.config.update({
    region: 'us-east-1'
  });

  const s3 = new AWS.S3();
  const filePath = `accounts/${currentAccountId}/projects/${id}/videos/${videoFile?.name}`;
  const s3Params = {
    Bucket: 'intrprtr',
    Key: filePath,
    ContentType: 'video/mp4',
    ACL: 'bucket-owner-full-control'
  };

  const signedUrl = await s3.getSignedUrl('putObject', s3Params);
  setLoadingMessage('Uploading Video File');

  return await fetch(signedUrl, {
    method: 'PUT',
    body: videoFile,
    headers: {
      'Content-Type': 'video/mp4'
    }
  });
};
