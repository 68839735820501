import { useEffect, useState } from 'react';
import './copy_text.scss';
import Services from '../Services/Services';
import { Button } from "@mui/material";
import { withStyles } from '@mui/styles';
import { RxQuestionMark } from 'react-icons/rx';
import { GiProcessor } from "react-icons/gi";
import { IoSettings } from 'react-icons/io5';
import { IoHardwareChip } from 'react-icons/io5';
import { AiFillSafetyCertificate } from "react-icons/ai";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GoWorkflow } from "react-icons/go";
import { MdOutlineEmail } from "react-icons/md";

const ButtonWithStyles = withStyles({
  root: {
    fontFamily: 'inherit !important',
    fontWeight: 'bold !important',
    fontSize: '20px !important'
  }
})(Button)

const CopyText = ({ handleOpen }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true)
  }, []);

  const classes = `copy-text-root ${visible ? 'visible' : ''}`;

  return (
    <div className={classes}>
      <div className='copy-main-text'>
        <div className="copy-paragraph-wrapper">

          <div className="block-wrapper">
            <h2>
              <GoWorkflow />
            </h2>
            <p>
              Intrprtr is an AI-powered video platform and metadata service that processes and extracts context from your video content at scale.
            </p>
          </div>

          <div className="block-wrapper">
            <h2>
              <IoHardwareChip />
            </h2>
            <p>
              The core of our service is built to work in parallel with your existing media pipeline, operating in the background and leaving you to focus on improving your product instead of analyzing content frame-by-frame.
            </p>
          </div>
          <div className="block-wrapper">
            <h2>
              <AiFillSafetyCertificate />
            </h2>
            <p>
              The metadata context produced can be used with AI models to automate Brand Safety audits, provide robust zero-effort SEO data, and carry out unparalelled content matching.
            </p>
          </div>
          <div className="block-wrapper">
            <h2>
              <AiOutlineCloudServer />
            </h2>
            <p>
              Our system integrates to run entirely on your own infrastructure, preventing proprietary or sensitive data from being exposed to third parties.
            </p>
          </div>
          <div className="block-wrapper">
            <h2>
              <MdOutlineEmail />
            </h2>
            <p>
              To schedule a demo to learn more, or if you'd like to try out our service, contact us at <a href="mailto:sales@intrprtr.io">sales@intrprtr.io</a>
            </p>
          </div>
          {/* <ButtonWithStyles onClick={handleOpen}>View Sample Videos</ButtonWithStyles> */}
        </div>
      </div>
      <Services />
    </div>
  )
}

export default CopyText;
