import { reducerActions } from "constants/reducerActions"
import { invokeLabelDetection } from "./aws/invokeLabelDetection"
import { invokeNSFWProcess } from "./aws/invokeNSFWProcess"
import { invokeTextGenerationProcess } from "./aws/invokeTextGeneration"
import { invokeTranscriptionProcess } from "./aws/invokeTranscription"

const {
  SET_PROBLEMATIC_CONTENT_RESULTS,
  SET_VIDEO_TEXT_RESULTS,
  SET_LABEL_DETECTION_RESULTS,
  SET_TRANSCRIPT_RESULTS,
  UPDATE_PROCESS_STARTED_STATE
} = reducerActions;

const typeHandlerMap = {
  'problematic_content': {
    handler: invokeNSFWProcess,
    action: SET_PROBLEMATIC_CONTENT_RESULTS
  },
  'video_text': {
    handler: invokeTextGenerationProcess,
    action: SET_VIDEO_TEXT_RESULTS
  },
  'label_detection': {
    handler: invokeLabelDetection,
    action: SET_LABEL_DETECTION_RESULTS
  },
  'transcript': {
    handler: invokeTranscriptionProcess,
    action: SET_TRANSCRIPT_RESULTS
  },
}

export const invokeProcessAndStartPinging = ({
  startPinging,
  videoFile,
  selectedOptions,
  currentAccountId,
  dispatch
}) => {
  const defaultOptions = [
    'problematic_content',
    'video_text',
    'label_detection',
    'transcript'
  ];
  console.log('options', selectedOptions)
  const options = selectedOptions || defaultOptions;
  console.log('options to use', options)
  options.forEach(option => {
    const handler = typeHandlerMap[option].handler;
    handler(videoFile, currentAccountId);
    // Update the project config to know the process has started
    dispatch({
      type: UPDATE_PROCESS_STARTED_STATE.actionName,
      payload: {
        process: option
      }
    })
    startPinging({ action: typeHandlerMap[option].action })
  })
}