import { AuthContextProvider } from "./AuthContextProvider/AuthContextProvider";
import { ConfigProvider } from "./ConfigContextProvider/ConfigContextProvider";
import { LanguageProvider } from "./LanguageContextProvider/LanguageContextProvider";
import { VideoRefContextProvider } from "./VideoRefProvider/VideoRefProvider";

const Providers = ({ children }) => {
  return (
    <AuthContextProvider>
      <VideoRefContextProvider>
        <LanguageProvider>
          <ConfigProvider>
            {children}
          </ConfigProvider>
        </LanguageProvider>
      </VideoRefContextProvider>
    </AuthContextProvider>
  )
}

export default Providers;