import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;

export const invokeTranscode = async (fileUri, uid) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey } = getQueryParams(params, ['apiKey']);

  try {
    const invokeTranscodeResponse = await fetch(`https://8p9gaic31e.execute-api.us-east-1.amazonaws.com/default/intrprtr_transcode?apikey=${apiKey}&fileUri=${fileUri}&userId=${uid}`);
    console.log('transcode process starting')
    return invokeTranscodeResponse;
  } catch (error) {
    console.error(error)
  }

};
