import React, { useEffect, useState } from 'react';
import UploadVideo from './UploadVideo';
import './quickstart_workflow.scss';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import useAuthContext from 'providers/AuthContextProvider/AuthContextProvider';
import useDetermineProjectStatusAndUpdateUrl from 'utils/determineProjectStatusAndUpdateUrl';

const QuickStartWorkflow = () => {
  const [activeStep, setActiveStep] = useState(1);
  const { currentAccountId } = useAuthContext();
  const { projectConfig = {} } = useConfigContext();
  const { checkAndUpdatePath } = useDetermineProjectStatusAndUpdateUrl();

  const renderStep = () => {
    return <UploadVideo setActiveStep={setActiveStep} />;
  };

  const getStepClassName = (step) => {
    const active = activeStep === step ? "active" : null
    return `step ${active}`;
  }

  const getStepHeadings = () => {
    const steps = [
      {
        id: 1,
        className: getStepClassName(1),
        value: 'Step 1. Upload',
      },
      {
        id: 2,
        className: getStepClassName(2),
        value: 'Step 2. Process',
      },
      {
        id: 3,
        className: getStepClassName(3),
        value: 'Step 3. Optimize',
      }
    ];

    return steps.map(s => {
      return (
        <span {...s}>
          <h2 id={s.id}>{s.value}</h2>
        </span>
      )
    })
  };

  useEffect(() => {
    if (currentAccountId) {
      checkAndUpdatePath({ projectConfig, currentAccountId });
    }
  }, [currentAccountId]);

  return (
    <div className="workflow-helper">
      <div className="step-headings">
        {getStepHeadings()}
      </div>
      {renderStep()}
    </div>
  );
};

export default QuickStartWorkflow;
