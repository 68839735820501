export const mockProjectData = [
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript', 'video_text']
  },
  {
    title: "this is a title",
    thumbnail: 'https://andhemac-apps.s3.amazonaws.com/images/homer.jpeg',
    duration: 347,
    project_id: 'bc4b609f-6c95-49ba-b832-2ce366c0cadb',
    processing_complete: ['transcript ', 'video_text ']
  }
];

export const processingCompleteOptions = {
  transcript: ' Transcription ',
  video_text: ' Text Exctraction ',
  labels: ' Label Detection ',
  problematic_content: ' NSFW Content '
}