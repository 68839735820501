import ProjectList from '../ProjectList';
import SearchBarWithOverlay from '../SearchBar/SearchBar';
import './project_list_container.scss';


const ProjectListContainer = ({ projectObjects }) => {

  return (
    <div className="account-project-list-container-root">
      {/* <div>search</div> */}
      <div className="heading-and-search-bar">
        <h2 className="project-list-heading">Projects</h2>
        <div className="search-bar-outer-wrapper">
          <SearchBarWithOverlay />
        </div>
      </div>
      <ProjectList projectObjects={projectObjects} />
    </div>
  )
}

export default ProjectListContainer; 
