import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const MetadataCheckboxItem = ({ name, label, onChange, value, shouldDefaultOn, shouldDefaultDisabled }) => {

  const disabledProps = (shouldDefaultOn) => {
    if (shouldDefaultOn) {
      return {
        checked: true,
        disabled: true
      }
    }
    return {};
  };

  let controlContent;

  if (shouldDefaultOn && shouldDefaultDisabled) {
    controlContent = (
      <Checkbox
        onChange={onChange}
        name={name}
        value={value}
        className='metadata-type-checkbox'
        checked={true}
        disabled={true}
      />
    )
  } else if (shouldDefaultOn && !shouldDefaultDisabled) {
    controlContent = (
      <Checkbox
        onChange={onChange}
        name={name}
        value={value}
        className='metadata-type-checkbox'
        checked={true}
        disabled={false}
      />
    )
  } else {
    controlContent = (
      <Checkbox
        onChange={onChange}
        name={name}
        value={value}
        className='metadata-type-checkbox'
        {...disabledProps(shouldDefaultOn)}
      />
    );
  }

  return (
    <FormControlLabel
      control={controlContent}
      label={label}
    />
  )
}

export default MetadataCheckboxItem;
