export const getAreProcessesFinished = (video_metadata = {}) => {
  const incompleteProcesses = Object.keys(video_metadata).filter(m => {
    if (m === 'optimizations') {
      return false;
    }

    const { processStarted, processCompleted } = video_metadata[m];
    return processStarted && !processCompleted;
  });

  return !incompleteProcesses.length
}

export const numberOfProcessStarted = (video_metadata) => {
  const startedProcesses = Object.keys(video_metadata).filter(m => {
    if (m === 'optimizations') {
      return false;
    }

    const { processStarted } = video_metadata[m];
    return processStarted;
  })

  return startedProcesses.length;
}