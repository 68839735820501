import { getQueryParams } from 'utils/getQueryParams';

const AWS = require('aws-sdk')

window.aws = AWS;

export const updateProjectState = async (projectJson, currentAccountId) => {
  const params = new URLSearchParams(window.location.search);
  const { apiKey } = getQueryParams(params, ['apiKey']);
  console.log('update project')
  try {
    const auth = await fetch(`https://b0dyd33dl4.execute-api.us-east-1.amazonaws.com/default/get_signed_token?apikey=${apiKey}`);

    const authData = await auth.json();

    AWS.config.credentials = {
      accessKeyId: authData.AccessKeyId,
      secretAccessKey: authData.SecretAccessKey,
      sessionToken: authData.SessionToken,
    };

    AWS.config.update({
      region: 'us-east-1'
    });

    const s3 = new AWS.S3();
    const filePath = `accounts/${currentAccountId}/projects/${projectJson?.id}/project.json`;
    const s3Params = {
      Bucket: 'intrprtr',
      Key: filePath,
      ContentType: 'application/json',
      ACL: 'bucket-owner-full-control'
    };

    const signedUrl = await s3.getSignedUrl('putObject', s3Params);
    const blob = new Blob([JSON.stringify(projectJson, null, 2)], { type: 'application/json' });
    const file = new File([blob], "sample.json", { type: "application/json" });

    const putResponse = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const json = await putResponse.json();
  } catch (error) {
    console.warn(error)
  }
}