export const processTypes = [
  {
    name: 'NSFW Content',
    label: 'NSFW Content',
    value: 'problematic_content',
    tabNumber: 1
  },
  {
    name: 'Video Text',
    label: 'Video Text',
    value: 'video_text',
    tabNumber: 2
  },
  {
    name: 'Label Detection',
    label: 'Label Detection',
    value: 'label_detection',
    tabNumber: 3
  },
  {
    name: 'Transcript',
    label: 'Transcript',
    value: 'transcript',
    tabNumber: 4
  },
];