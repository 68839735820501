// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDmMzMc1bzsfZrD6weRoZadvWSCsObWTRE",
  authDomain: "intrprtr-fcdac.firebaseapp.com",
  projectId: "intrprtr-fcdac",
  storageBucket: "intrprtr-fcdac.appspot.com",
  messagingSenderId: "936389572697",
  appId: "1:936389572697:web:9e4bfd1e8a3d0e2e299948",
  measurementId: "G-G9NYQ86KK0"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);

// const analytics = getAnalytics(app);

