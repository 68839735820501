import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ImUpload3 } from 'react-icons/im';
import './video_details_input_fields.scss';
import { Button } from '@mui/material';
import UploadModal from '../UploadModal/UploadModal';
import useConfigContext from 'providers/ConfigContextProvider/ConfigContextProvider';
import { reducerActions } from 'constants/reducerActions';
import usePingHlsSourceUrl from 'utils/aws/pingHlsSourceUrl';

const VideoDetailsInputFields = ({ source, createdAt, duration }) => {
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState({})

  const { dispatch } = useConfigContext();
  const { projectConfig } = useConfigContext();
  const { processingMessage } = usePingHlsSourceUrl();

  const { SET_TITLE, SET_DESCRIPTION } = reducerActions;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e, actionType) => {
    setDetails(state => {
      return {
        ...state,
        [actionType.propName]: e.target.value
      }
    });

    dispatch({ type: actionType.actionName, payload: e.target.value });
  }

  useEffect(() => {
    setDetails(projectConfig);
  }, [JSON.stringify(projectConfig)])

  return (
    <div className="video-details-input-fields">
      <div className='details-section title-section'>
        <h2>
          <label>
            Title
          </label>
        </h2>
        <TextField
          className="video-details-input-field"
          InputProps={{
            className: 'video-details-input-fields__input',
          }}
          onChange={(e) => { onChange(e, SET_TITLE) }}
          placeholder='Write your title here'
          value={details?.project?.title}
          variant="outlined"
        />
      </div>
      <div className='details-section description-section'>
        <h3>
          <label>Description</label>
        </h3>
        <TextField
          aria-label="Description"
          className="video-details-input-field"
          minRows={5}
          multiline
          onChange={(e) => { onChange(e, SET_DESCRIPTION) }}
          placeholder='write a description'
          value={details?.project?.description}
        />
      </div>
      <div className='details-section source-section'>
        <h3>
          <label>
            Sources
          </label>
        </h3>
        <div className='source-mp4-section'>
          <h4 className='source-field-label'>
            <label>
              mp4
            </label>
          </h4>
          <TextField
            variant="outlined"
            disabled
            value={details?.video?.mp4 || 'click icon to change mp4 video'}
            className="video-details-input-field disabled-field"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button disabled={!!details?.video?.mp4} onClick={handleOpen} className='details-input-upload-button' variant='contained'>
                    <ImUpload3 className='details-input-upload-button-icon' />
                  </Button>
                </InputAdornment>
              ),
              className: 'video-details-input-fields-source',
            }}
          />
        </div>
        <div className='source-hls-section'>
          <h4 className="source-field-label">
            <label>
              hls
              {/* <span className='transcoding-message'>{processingMessage}</span> */}
            </label>
          </h4>
          <TextField
            variant="outlined"
            disabled
            value={details?.video?.hls || 'hls transcoding is currently disabled'}
            className="video-details-input-field disabled-field"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button disabled={!!details?.video?.hls} onClick={handleOpen} className='details-input-upload-button' variant='contained'>
                    <ImUpload3 disabled className='details-input-upload-button-icon' />
                  </Button>
                </InputAdornment>
              ),
              className: 'video-details-input-fields-source',
            }}
          />
        </div>
      </div>
      <div className='details-section source-section'>
        <h3>
          <label>
            Data URL
          </label>
        </h3>
        <div className='source-hls-section'>
          <TextField
            variant="outlined"
            disabled
            value={details?.dataUrl || 'the data URL will be updated once it is available'}
            className="video-details-input-field disabled-field"
          />
        </div>
      </div>
      <div className='details-section timing-section'>
        <div className='timing-created-at-section global-margin-right-5px timing-section-field'>
          <h3>
            <label>
              Created at
            </label>
          </h3>
          <TextField
            variant="outlined"
            value={createdAt || 'null'}
            disabled
            className="video-details-input-field disabled-field"
            InputProps={{
              className: 'video-details-input-fields__input',
            }}
          />
        </div>
        <div className='timing-duration-section timing-section-field'>
          <h3>
            <label>
              Duration
            </label>
          </h3>
          <TextField
            variant="outlined"
            value={duration || 'Duration will be shown here'}
            disabled
            className="video-details-input-field disabled-field"
            InputProps={{
              className: 'video-details-input-fields__input',
            }}
          />
        </div>
      </div>
      <UploadModal handleClose={handleClose} open={open} />
    </div>
  );
};

export default VideoDetailsInputFields;