import { Button, TableCell, TableRow, TextField } from "@mui/material"
import useVideoRefContext from "providers/VideoRefProvider/VideoRefProvider"
import { useEffect } from "react"
import { VscTrash } from "react-icons/vsc"

const TranslationTableRow = ({ id, content, lang, dispatch }) => {
  const { videoElRef } = useVideoRefContext();
  const onClickField = () => { 
    videoElRef?.current?.eventRelay.emit('pause')
  }

  return (
    <TableRow key={id} className="translation-table-row">
      <TableCell className='translation-table-cell'>
        <TextField
          defaultValue={content['en']}
          className='translation-text-field'
          onFocus={() => { onClickField() }} 
        />
      </TableCell>
      <TableCell className='translation-table-cell'>
        <TextField
          className='translation-text-field'
          value={content[lang]}
          disabled={lang === 'en'}
        />
      </TableCell>
      <TableCell className='translation-table-cell entry-delete-cell'>
        <Button
          onClick={() => {
            dispatch({
              type: 'DELETE_TEXT_ENTRY',
              payload: { id: id }
            })
          }} 
          className="translation-entry-delete-button"
        >
          <VscTrash />
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default TranslationTableRow;
